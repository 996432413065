import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { routes, confirmationTitle, confirmationSubtitle } from '@constants';

function OrderConfirmation() {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(routes.home)
    }, 3000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <Box sx={{ height: '100svh', display: 'flex', }}>
      <Box sx={{ px: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', m: 'auto' }}>
        <CheckCircleOutlineOutlinedIcon sx={{ width: '100px', height: '100px', color: 'rgba(58, 208, 255, 1)', mb: '10px' }} />
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={confirmationTitle}
        >
          Thanks for placing the order
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={confirmationSubtitle}
        >
          Please check your inbox for more details
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderConfirmation;