import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import Modal from 'simple-react-modal';
import { createNewPassword } from '@api/auth';
import { errorHandler } from '@helpers/error-handler';
import { getItemFromLocalStorage } from '@helpers/localstorage-helper';
import { routes, storageNames, fieldAttrs } from '@constants';
import { AuthTitle } from '@components/molecules/AuthTitle';
import { InputField } from '@components/atoms/InputField';
import { createNewPasswordSchema } from '../../validation';

import styles from '../ForgotPassword/forgotPassword.module.scss';
import buttonStyles from '@assets/styles/buttons.module.scss';

const CreatePassword = () => {
  const navigate = useNavigate();
  const [modal, setToggleModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({ mode: 'onTouched', resolver: yupResolver(createNewPasswordSchema) });

  const moveToLogin = () => {
    setToggleModal(false);
    navigate(routes.login);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const savedUserEmail = getItemFromLocalStorage(storageNames.forgotPassEmail);
      if (!savedUserEmail) return;

      await createNewPassword({ ...data, email: savedUserEmail });

      toast.success('Password has been changed successfully.');
      setToggleModal(true);
    } catch (error) {
      errorHandler(error);
    }
  });

  return (
    <section id="createPassword" className={styles.forgotpass__section}>
      <div className="container">
        <div className="row">
          <Modal
            className="customModal"
            containerClassName="customModal__inner"
            transitionSpeed={1500}
            show={modal}
          >
            <div>
              <div className={styles.modal_title_wrapper}>
                <h3 className={styles.modal_title}>Success</h3>
                <p className={styles.modal_subtitle}>Password has been changed successfully.</p>
              </div>

              <button type="button" className={buttonStyles.modal__button} onClick={moveToLogin}>
                Ok
              </button>
            </div>
          </Modal>
          <div className={styles.forgotpass__wrapper}>
            <div className={styles.forgotpass__fields__wrapper}>
              <form onSubmit={onSubmit} className={styles.form__wrapper}>
                <div>
                  <AuthTitle title={'Create password'} subTitle={'Please, enter new password.'} />

                  <InputField
                    register={register}
                    error={errors.password}
                    properties={fieldAttrs.password}
                  />
                  <InputField
                    register={register}
                    error={errors.repeatPassword}
                    properties={fieldAttrs.repeatPassword}
                  />
                </div>

                <button
                  type="submit"
                  className={cn(buttonStyles.forgotpass__button, {
                    [buttonStyles.button_disabled]: !isValid,
                  })}
                  disabled={isSubmitting || !isValid}
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreatePassword;
