import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { routes } from '@constants';

import formStyles from '@assets/styles/forms.module.scss';
import buttonStyles from '@assets/styles/buttons.module.scss';
import feedbackStyles from '@assets/styles/feedback.module.scss'

function Feedback() {
    const { register, handleSubmit, reset } = useForm();
    const navigate = useNavigate()

    const onSubmit = (data) => {
        console.log(data)
        reset()
        toast.success('Thank you for your feedback')
        navigate(routes.confirmation)
    }

  return (
    <section>
        <div className="container">
            <div className="row">
                <form onSubmit={handleSubmit(onSubmit)} className={formStyles.form__wrapper}>
                    <h1 className="section__title">
                        Leave us a message!
                    </h1>
                    <textarea 
                        name="feedback"
                        {...register('feedback')}
                        className={feedbackStyles.feedback__text}
                        placeholder='Your Message...'
                    />
                    <button 
                        className={buttonStyles.next__button}
                        type='submit'
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    </section>
  )
}

export default Feedback
