import buttonStyles from '@assets/styles/buttons.module.scss';

export const ButtonOutlined = ({
  color,
  width,
  height = '56px',
  fontSize,
  onClick,
  disabled = false,
  children,
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={buttonStyles.button_outlined}
      style={{ color, width, height, fontSize }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
