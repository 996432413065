import React, { useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import { Outlet } from 'react-router-dom';
import { useOrder } from './OrderContext';
import { refresh } from '@api/auth';
import Login from '../pages/Login';
import { setItemToLocalStorage } from '@helpers/localstorage-helper';
import { storageNames } from '@constants';
import CircularProgress from '@mui/material/CircularProgress';

export const PrivateRoute = () => {
  const { user, setUser } = useOrder();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      setIsLoading(true);

      refresh()
        .then(({ data }) => {
          const { tokens, user } = data;

          setItemToLocalStorage(storageNames.tokens, tokens);
          setUser(user);
        })
        .catch(console.log)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [setUser, user]);

  if (user) {
    return <Outlet />;
  }

  return isLoading ? (
    <div className="flex-center">
      <CircularProgress color="green" />
    </div>
  ) : (
    <Login />
  );
};
