export const colors = {
  white: '#ffffff',
  green: '#006875',
  blue: '#BDE0FE',
  darkBlue: 'rgba(142, 202, 230, 1)',
  yellow: '#FFE08E',
  black: '#000000',
  pink: 'rgba(255, 216, 228, 1)',
  darkPink: 'rgba(61, 6, 0, 1)',
  darkGray: '#DBE4E6'
};
