import styles from '@assets/styles/inputFields.module.scss'

export const InputBlockNew = ({ inputName, children }) => {
  return (
    <div className={styles.input_container}>
      <h3 className={styles.input__label}>{inputName}</h3>
      {children}
    </div>
  );
};
