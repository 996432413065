import { ButtonsWrapper } from '../atoms/ButtonsWrapper';
import { ButtonColored, ButtonOutlined } from '../atoms/buttons';
import { colors } from '../../constants';

import imagesStyles from '@assets/styles/images.module.scss';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export const VideoPreview = ({ preview, handleRetake, handleSave, isLoading }) => {
  return (
    <div className={imagesStyles.preview_wrapper}>
      <div className={imagesStyles.preview}>
        <video
          style={{
            width: '100%',
            height: 'calc(100svh - 115px)',
          }}
          src={preview}
          autoPlay
          muted
          loop
          playsInline
          controls
        />
      </div>

      <div className="flex-center">
        <ButtonsWrapper>
          <ButtonOutlined onClick={handleRetake} color={colors.black}>
            Retake
          </ButtonOutlined>

          <ButtonColored color={colors.black} backgroundColor={colors.yellow} onClick={handleSave}>
            {isLoading ? <CircularProgress color="green" /> : 'Submit'}
          </ButtonColored>
        </ButtonsWrapper>
      </div>
    </div>
  );
};
