import buttonStyles from '@assets/styles/buttons.module.scss';

export const ButtonColored = ({
  color,
  backgroundColor,
  width,
  height = '56px',
  fontWeight = '600',
  disabled = false,
  type = 'button',
  onClick,
  children,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={buttonStyles.button_colored}
      style={{ color, backgroundColor, width, height, fontWeight }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
