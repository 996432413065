import React from 'react';
import { footZones, zoneColors } from '@constants';

import styles from '../../assets/styles/pain-map.module.scss';

export const RightFootMap = ({ onStateChange, rightFoot }) => {
  return (
    <div className={styles.wrapper} style={{ right: '-5%' }}>
      <svg
        width="226"
        height="385"
        viewBox="0 0 283 484"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          onClick={() => onStateChange(footZones.bigToe)}
          d="M69.2328 90.5834L69.2319 90.5848C67.1029 94.0438 65.2623 96.0273 62.0846 98.2644C56.4183 102.249 49.8735 102.802 43.6688 100.385C37.4519 97.9641 31.5548 92.549 27.2732 84.5662C24.3959 79.1634 23.2145 75.4003 22.097 68.0413C20.9855 60.7206 20.9964 56.7759 22.1348 50.7237C25.1524 35.1277 35.369 25.3117 46.993 26.3345C54.8669 27.0312 62.8868 32.8476 68.1422 41.8524C77.018 57.0607 77.4444 77.3411 69.2328 90.5834Z"
          fill={rightFoot.bigToe ? zoneColors.selected : zoneColors.unselected}
          stroke="black"
        />
        <path
          onClick={() => onStateChange(footZones.innerForeFoot)}
          d="M70.5603 125.783C76.4835 125.893 82.2812 128.746 85.8899 133.345C91.9752 141.098 91.6352 151.826 85.07 159.14C83.3632 161.054 81.9082 162.183 79.4521 163.484C70.6307 168.153 59.5922 165.551 53.7569 157.376C51.7955 154.606 51.0407 152.642 50.4566 148.795C49.8755 144.968 50.0133 142.869 51.0611 139.622C53.8166 131.272 61.776 125.624 70.5603 125.783Z"
          fill={rightFoot.innerForeFoot ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.foreFoot)}
          d="M127.56 125.783C133.484 125.893 139.281 128.746 142.89 133.345C148.975 141.098 148.635 151.826 142.07 159.14C140.363 161.054 138.908 162.183 136.452 163.484C127.631 168.153 116.592 165.551 110.757 157.376C108.795 154.606 108.041 152.642 107.457 148.795C106.876 144.968 107.013 142.869 108.061 139.622C110.817 131.272 118.776 125.624 127.56 125.783Z"
          fill={rightFoot.foreFoot ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.outerForeFoot)}
          d="M180.56 153.783C186.484 153.893 192.281 156.746 195.89 161.345C201.975 169.098 201.635 179.826 195.07 187.14C193.363 189.054 191.908 190.183 189.452 191.484C180.631 196.153 169.592 193.551 163.757 185.376C161.795 182.606 161.041 180.642 160.457 176.795C159.876 172.968 160.013 170.869 161.061 167.622C163.817 159.272 171.776 153.624 180.56 153.783Z"
          fill={rightFoot.outerForeFoot ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.outerMidFoot)}
          d="M157.56 279.783C163.484 279.893 169.281 282.746 172.89 287.345C178.975 295.098 178.635 305.826 172.07 313.14C170.363 315.054 168.908 316.183 166.452 317.484C157.631 322.153 146.592 319.551 140.757 311.376C138.795 308.606 138.041 306.642 137.457 302.795C136.876 298.968 137.013 296.869 138.061 293.622C140.817 285.272 148.776 279.624 157.56 279.783Z"
          fill={rightFoot.outerMidFoot ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.innerMidFoot)}
          d="M87.5603 239.783C93.4835 239.893 99.2812 242.746 102.89 247.345C108.975 255.098 108.635 265.826 102.07 273.14C100.363 275.054 98.9082 276.183 96.4521 277.484C87.6307 282.153 76.5922 279.551 70.7569 271.376C68.7955 268.606 68.0407 266.642 67.4566 262.795C66.8755 258.968 67.0133 256.869 68.0611 253.622C70.8166 245.272 78.776 239.624 87.5603 239.783Z"
          fill={rightFoot.innerMidFoot ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.heel)}
          d="M115.56 401.783C121.484 401.893 127.281 404.746 130.89 409.345C136.975 417.098 136.635 427.826 130.07 435.14C128.363 437.054 126.908 438.183 124.452 439.484C115.631 444.153 104.592 441.551 98.7569 433.376C96.7955 430.606 96.0407 428.642 95.4566 424.795C94.8755 420.968 95.0133 418.869 96.0611 415.622C98.8166 407.272 106.776 401.624 115.56 401.783Z"
          fill={rightFoot.heel ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.fourthToe)}
          d="M116.794 50.3931L116.794 50.3933C117.529 52.0357 118.306 55.4807 118.842 59.0384C119.377 62.5955 119.652 66.1463 119.428 67.9926L119.428 67.9942C118.384 76.8266 114.515 84.1557 109.003 87.9881C107.894 88.7528 107.287 89.1341 106.53 89.4032C105.759 89.6776 104.815 89.842 103.016 90.1151C101.237 90.3852 100.286 90.5085 99.4681 90.4755C98.6663 90.4431 97.9728 90.2595 96.7075 89.8561L96.7075 89.8561L96.7032 89.8547C89.7603 87.7105 84.1856 80.3472 82.1884 70.4892C80.4708 61.9585 81.8827 53.5448 85.2868 47.3002C88.6936 41.0507 94.049 37.0395 100.214 37.1207C106.693 37.209 113.19 42.3242 116.794 50.3931Z"
          fill={rightFoot.fourthToe ? zoneColors.selected : zoneColors.unselected}
          stroke="black"
        />
        <path
          onClick={() => onStateChange(footZones.thirdToe)}
          d="M147.862 47.5114L147.862 47.5115L147.871 47.5138C150.694 48.1825 152.392 48.9667 154.518 50.7076L154.52 50.7088C158.534 53.9688 161.221 59.311 162.29 65.4542C163.357 71.5915 162.799 78.4819 160.373 84.7847C159.548 86.9138 158.162 89.3342 156.598 91.5033C155.032 93.6748 153.315 95.5564 151.842 96.6359C150.659 97.4607 148.675 98.5974 147.426 99.1243L147.426 99.1245C139.771 102.362 131.293 98.0501 127.016 88.4201C126.356 86.9231 125.709 84.2136 125.264 81.3502C124.821 78.4918 124.59 75.5537 124.733 73.614C125.494 63.8163 130.236 54.507 136.617 50.2168L136.62 50.2152C137.858 49.3721 138.629 48.9076 139.453 48.573C140.281 48.2372 141.185 48.0237 142.706 47.7153C145.426 47.1872 146.464 47.1502 147.862 47.5114Z"
          fill={rightFoot.thirdToe ? zoneColors.selected : zoneColors.unselected}
          stroke="black"
        />
        <path
          onClick={() => onStateChange(footZones.secondToe)}
          d="M197.864 80.3439L197.864 80.3449C199.013 85.2784 198.61 90.9676 196.985 96.2469C195.36 101.527 192.525 106.354 188.856 109.582L188.856 109.582L188.853 109.584C183.374 114.464 176.721 115.244 171.539 111.761C169.198 110.172 167.827 108.561 166.145 105.275C165.118 103.218 164.798 102.125 164.236 98.1688C163.674 94.2138 163.635 92.9549 164.073 90.083L164.074 90.0779C165.392 80.7965 169.633 73.4255 175.913 69.2514L175.913 69.2514L175.918 69.2478C176.929 68.5571 177.613 68.1536 178.389 67.8546C179.172 67.5532 180.07 67.3493 181.524 67.0898L181.526 67.0895C184.089 66.6231 185.229 66.5719 186.369 66.8569C192.051 68.3438 196.191 73.099 197.864 80.3439Z"
          fill={rightFoot.secondToe ? zoneColors.selected : zoneColors.unselected}
          stroke="black"
        />
        <path
          d="M193.69 232.17C189.736 249.165 186.895 265.831 183.713 290.923L183.713 290.923C181.52 308.315 181.128 315.419 180.706 346.722L181.206 346.729L180.706 346.722C180.13 389.739 179.285 404.472 176.743 415.544C173.948 427.675 167.275 440.022 158.569 450.355C149.862 460.691 139.15 468.978 128.307 473.027C124.142 474.574 117.593 475.496 111.823 475.333L111.821 475.333C97.0206 474.964 85.6707 470.083 76.4372 460.086L76.4356 460.084C67.1183 450.089 62.1014 438.082 59.1735 418.8C57.7603 409.493 57.2367 402.875 57.9942 394.409C58.7532 385.926 60.7992 375.576 64.5476 358.806C67.5013 345.6 69.0742 338.216 69.8052 332.105C70.5379 325.98 70.4241 321.141 70.0232 313.031C69.5019 301.957 68.786 294.948 66.9987 283.178C65.191 271.018 63.9718 265.284 61.0057 254.924C58.835 247.267 57.5342 243.83 52.7138 233.218L52.7136 233.218C45.3125 216.957 42.9881 210.009 37.9661 189.381L37.966 189.38C36.1385 181.913 33.4068 167.22 32.8013 161.714C31.3428 148.3 31.6354 139.569 33.7366 132.592L33.7374 132.589C35.048 128.154 36.8295 124.555 39.5574 121.181C42.2905 117.8 45.9887 114.625 51.1589 111.057L51.1606 111.056C56.265 107.503 60.3695 105.347 65.0927 103.786L65.0977 103.784C67.0963 103.1 70.8263 102.263 74.7134 101.556C78.596 100.85 82.5888 100.284 85.1033 100.132C110.039 98.6267 141.282 104.793 165.035 115.962C184.728 125.234 197.16 135.724 204.805 149.524C210.455 159.748 212.58 169.72 211.553 181.105C210.661 190.847 207.857 199.048 199.831 214.757L199.831 214.758C197.96 218.432 196.961 220.444 196.163 222.714C195.366 224.98 194.772 227.495 193.69 232.17ZM193.69 232.17L194.177 232.284L193.69 232.171C193.69 232.171 193.69 232.171 193.69 232.17Z"
          stroke="black"
        />
        <path
          onClick={() => onStateChange(footZones.pinkieToe)}
          d="M220.09 93.2284L220.09 93.2285L220.098 93.2304C225.21 94.4802 228.315 99.1928 229.01 105.223C229.704 111.245 227.966 118.47 223.494 124.488C219.831 129.403 215.904 131.909 211.212 132.32C209.526 132.461 208.243 132.389 207.087 132.052C205.935 131.716 204.871 131.105 203.639 130.11C201.169 128.051 199.756 125.377 199.088 121.222L199.088 121.221C198.446 117.242 198.612 114.664 199.734 110.638L199.734 110.637C201.483 104.274 204.827 99.314 209.593 95.9059L209.596 95.9034C210.855 94.9874 211.617 94.483 212.389 94.1364C213.157 93.7916 213.958 93.5929 215.311 93.3288L215.314 93.3282C217.651 92.8578 218.676 92.858 220.09 93.2284Z"
          fill={rightFoot.pinkieToe ? zoneColors.selected : zoneColors.unselected}
          stroke="black"
        />
      </svg>
    </div>
  );
};
