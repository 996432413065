export const dummyOrder = {
  _id: '6424a651b7b7d9e300f37d3c',
  orderId: 'HIK-000',
  status: 'Pending',
  email: 'test@hikemedical.com',
  firstName: 'Test',
  lastName: 'Test',
  companyId: '640b435e09071df922e6c3f5',
  companyCode: 'test',
  dateOfBirth: '2005-02-10',
  address: 'East Green Street, Ithaca, NY, USA',
  city: 'Ithaca',
  state: 'NY',
  zipCode: 14850,
  age: 18,
  gender: 'male',
  jobTitle: 'Dev',
  weight: '101-150',
  heightFeet: 1,
  heightInches: 1,
  leftFootSymptoms: [],
  rightFootSymptoms: [],
  description: null,
  rightDescription: null,
  shoeSize: 1,
  shoeWidth: 'normal',
  shoeBrand: 'Adidas',
  leftFootPainMap: [],
  rightFootPainMap: [],
  photoPath: null,
  backPhotoPath: null,
  leftVideoPath: null,
  rightVideoPath: null,
  isDiabetic: false,
  trackingNumber: null,
  leftScanPath: null,
  rightScanPath: null,
  deleted: false,
  createdAt: '2023-03-29T20:57:53.989Z',
  updatedAt: '2023-03-29T20:58:53.399Z',
  __v: 0,
};
