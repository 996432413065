import axios from 'axios';
import { getItemFromLocalStorage, setItemToLocalStorage } from '../helpers/localstorage-helper';
import { storageNames } from '../constants';
import { refresh } from './auth';
import toast from 'react-hot-toast';

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = 'http://localhost:8080';

export const API_URL = BASE_URL + '/api';

export const ENDPOINTS = {
  registration: '/auth/registration',
  login: '/auth/login',
  logout: '/auth/logout',
  refresh: API_URL + '/auth/refreshToken',
  forgotPassword: '/auth/forgotPassword',
  verifyCode: '/auth/verifyCode',
  createNewPassword: '/auth/createNewPassword',
  updatePatientInfo: (id) => `/users/${id}/patient-info`,
  orders: '/orders',
  completeOrder: (id) => `/orders/${id}/complete`,
  companyById: (id) => `/companies/${id}`,
  updateScanPaths: (id) => `/orders/${id}/scans`,
  allCompanies: () => `/companies/all`,
  customerReview: '/reviews/customer-review',
  videoCheck: '/video-check',
};

const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${
      getItemFromLocalStorage(storageNames.tokens)?.accessToken
    }`;
  }

  return config;
});

const UNAUTHORIZED = 401;

$api.interceptors.response.use(
  (config) => {
    return config.data;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === UNAUTHORIZED) {
      try {
        const { data } = await refresh();

        setItemToLocalStorage(storageNames.tokens, data.tokens);

        return $api.request(originalRequest);
      } catch (error) {
        localStorage.removeItem(storageNames.tokens);
        toast.error('Please, authorize');
        window.location.replace('/');
      }
    } else {
      const errors = error.response?.data?.errors;
      throw errors ? errors : ['Something went wrong'];
    }
  }
);

export default $api;
