import Box from '@mui/material/Box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '@mui/material/Button';
import { ContentContainer } from '../atoms/ContentContainer';

import buttonStyles from '@assets/styles/buttons.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../constants';
import { useOrder } from '../OrderContext';

export const Navigation = () => {
  const location = useLocation();
  const { setOrder } = useOrder();
  const navigate = useNavigate();
  const backDisabled =
    routes.home === location.pathname || routes.insoleFormLeft === location.pathname;

  const handleBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    setOrder(null);
    navigate(routes.home);
  };

  return (
    <ContentContainer>
      <Box
        sx={{
          display: 'flex',
          p: '32px 16px 0',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <button
          type="button"
          onClick={handleBack}
          className={buttonStyles.button_fab}
          style={{ visibility: backDisabled ? 'hidden' : 'visible' }}
          disabled={backDisabled}
        >
          <ArrowBackIosNewIcon sx={{ color: '#000000' }} />
        </button>

        <Button
          onClick={handleCancel}
          sx={{
            color: '#3D0600',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            textTransform: 'capitalize',
          }}
        >
          Cancel
        </Button>
      </Box>
    </ContentContainer>
  );
};
