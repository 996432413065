import React, { useState } from 'react';
import cn from 'classnames';
import { InputBlockNew } from './InputBlockNew';
import { fieldTypes } from '@constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import styles from '@assets/styles/inputFields.module.scss';
import errorsStyles from '@assets/styles/errors.module.scss';

export const InputField = ({ register, defaultValue, error, properties }) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown((prev) => !prev);
  };

  return (
    <InputBlockNew inputName={properties.label}>
      <div className={styles.field__wrapper}>
        <input
          {...register(properties.name)}
          id={properties.name}
          type={passwordShown ? fieldTypes.TEXT : properties.type}
          placeholder={properties.placeholder}
          className={cn(styles.input__new_design, {[errorsStyles.input_error]: error})}
          defaultValue={defaultValue || ''}
        />
        
        {(
          properties.name === fieldTypes.PASSWORD || properties.name === fieldTypes.REPEAT_PASSWORD) && (
            <div className={styles.password__eye_icon_wrapper}>
            {
              passwordShown
                ? (<VisibilityIcon className={styles.password__icon} onClick={togglePassword} />)
                :(<VisibilityOff className={styles.password__icon} onClick={togglePassword} />)
            }
            </div>
        )}
        
        <div className={errorsStyles.field_error}>{error?.message}</div>
      </div>
    </InputBlockNew>
  );
};
