import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { ButtonBlock, TextInput } from '../form-components';
import PhoneInputField from '../atoms/Inputs/PhoneInput';
import { ButtonColored } from '../atoms/buttons';
import { useOrder } from '@components/OrderContext';
import { colors, configureSteps } from '../../constants';
import { userBasicInfoSchema } from '../../validation';

import formStyles from '../../components//form-components/form.module.scss';

export const BasicInfo = ({ setConfigureStep }) => {
  const { user, setUser } = useOrder();
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userBasicInfoSchema),
    defaultValues: {
      firstName: user?.patientInfo?.firstName,
      lastName: user?.patientInfo?.lastName,
      jobTitle: user?.patientInfo?.jobTitle,
      phoneNumber: user?.patientInfo?.phoneNumber || '',
    },
    mode: 'onSubmit'
  });

  const onSubmit = handleSubmit(async (data) => {

    setUser(prev => ({
      ...prev,
      patientInfo: {
        ...prev.patientInfo,
        ...data
      }
    }))

    setConfigureStep(1)
  });

  return (
    <form onSubmit={onSubmit} className={formStyles.form}>
      <h1 className={formStyles.form_title}>{configureSteps.basic}</h1>
      <TextInput
        register={register}
        properties={{ name: 'firstName', label: 'First Name', placeholder: 'First name' }}
        error={errors?.firstName}
      />

      <TextInput
        register={register}
        properties={{ name: 'lastName', label: 'Last Name', placeholder: 'Last Name' }}
        error={errors?.lastName}
      />

      <TextInput
        register={register}
        properties={{
          label: 'Job Title',
          name: 'jobTitle',
          placeholder: 'Enter your job title',
        }}
        error={errors?.jobTitle}
      />

      <PhoneInputField setValue={setValue} watch={watch} error={errors?.phoneNumber} />

      <ButtonBlock marginTop="24px">
        <ButtonColored
          color={colors.white}
          backgroundColor={colors.green}
          onClick={onSubmit}
        >
          Next
        </ButtonColored>
      </ButtonBlock>
    </form>
  );
};
