import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#2C64EF',
    },
    secondary: {
      main: '#1A2E4F',
    },
    error: {
      main: '#F45532',
    },
    neutral: {
      main: '#6F797B',
      contrastText: '#fff',
    },
    green: {
      main: 'rgb(0, 104, 117)',
      contrastText: '#fff',
    },
  },
});

export default theme;