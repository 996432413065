import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import toast from 'react-hot-toast';
import { useOrder } from '@components/OrderContext';
import {
  routes,
  chooseMainTitle,
  cardMediaSoloNew,
  chooseCardTitleNew,
  chooseCardSubtitleNew,
  chooseSoloFlowCard,
  choosePartnerFlowCard,
  flowTypes
} from '@constants';
import SoloFlowMode from '@assets/solo_mode.png';
import PartnerFlowMode from '@assets/partner.png';

import styles from '@assets/styles/OrderStyles/chooseFlow.module.scss';
import { MainMenu } from '../MainMenu';

function ChooseFlow() {
  const navigate = useNavigate();
  const { user, setOrder, setFlowType } = useOrder();
  const [toggleMenu, setToggleMenu] = useState({
    top: false
  });

  const handleToggleMenu = (anchor, open) => () => {
    setToggleMenu((prev) => ({ ...prev, [anchor]: open }))
  }

  useEffect(() => {
    if (!user.patientInfo) {
      toast.error('Please, configure your account first');
      navigate(routes.configureAccount);
    }
  }, [navigate, user.patientInfo]);

  const handleFlowType = (flowType) => {
    const { email, companyCode, patientInfo } = user;
    const { _id, ...restPatientInfo } = patientInfo;

    setFlowType(flowType);
    setOrder((prev) => ({
      ...prev,
      ...restPatientInfo,
      email,
      companyCode,
      uniqId: uuidv4(),
    }));

    navigate(routes.insoleForm);
  };

  return (
    <section id="landing">

      <div className="container">
        <div className="row">
          <div className={styles.choose__wrapper}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '10px',
                mt: '60px',
              }}
            >
              <MainMenu open={toggleMenu} setToggleMenu={handleToggleMenu} />
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={chooseMainTitle}
              >
                Hike Medical
              </Typography>
              <Button sx={{ minWidth: '48px', height: '48px', backgroundColor: '#DBE4E6', borderRadius: '48px', p: 0 }} onClick={handleToggleMenu('top', true)}>
                <MenuRoundedIcon color="secondary" fontSize="large" />
              </Button>
            </Stack>
            <Card sx={{ ...chooseSoloFlowCard, position: 'relative',  justifyContent: 'center', alignItems: 'center' }} onClick={() => handleFlowType(flowTypes.withoutPartner)}>
  <CardMedia
    sx={cardMediaSoloNew}
  
    image={SoloFlowMode}
    title="Solo flow mode"
  />
  <CardContent sx={{ p: '0 !important', position: 'relative', mb: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <Typography
      gutterBottom
      variant="h5"
      component="div"
      sx={chooseCardTitleNew}
    >
      Solo Mode
    </Typography>
    <Typography
      variant="body2"
      color="text.secondary"
      sx={chooseCardSubtitleNew}
    >
      Self-guided scanning made easy
    </Typography>
  </CardContent>
</Card>


            <Card sx={{ ...chooseSoloFlowCard, ...choosePartnerFlowCard, position: 'relative',  justifyContent: 'center', alignItems: 'center' }} onClick={() => handleFlowType(flowTypes.withPartner)}>
              <CardMedia
                sx={cardMediaSoloNew}
                image={PartnerFlowMode}
                title="Partner flow mode"
              />
              <CardContent sx={{ p: '0 !important', position: 'relative', mb: '8px' }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                    fontWeight: 700,
                    fontSize: '40px',
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                    color: '#95DEA7',
                    marginBottom: '10px !important',
                    textAlign: 'center'
                  }}
                >
                  Partner Mode
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontStyle: 'normal',
                    fontFamily: 'Noto Sans',
                    fontWeight: 300,
                    fontSize: '18px',
                    lineHeight: '16px',
                    letterSpacing: '0.5px',
                    color: '#95DEA7',
                    textAlign: 'center'
                  }}
                >
                  Scan better, together
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChooseFlow;
