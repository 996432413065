import React, { useState } from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import ratingStyles from '@assets/styles/rating.module.scss';

function NumberRating({ name }) {
  const { register } = useFormContext();
  const [selectedValue, setSelectedValue] = useState(null);

  const handleRatingChange = (chosenValue) => {
    setSelectedValue(chosenValue);
    register(name, { value: chosenValue, shouldValidate: true });
  };

  return (
    <div className={ratingStyles.rating__row}>
      {[...Array(10)].map((_, index) => (
        <span
          key={index + 1}
          onClick={() => handleRatingChange(index + 1)}
          className={cn(
            ratingStyles.rating__number,
            {[ratingStyles.rating__number_active]: selectedValue === index + 1}
          )}
        >
          {index + 1}
        </span>
      ))}
    </div>
  );
}

export default NumberRating;
