import React, { useState } from 'react';
import cn from 'classnames';
import { InputCheckbox } from '@components/molecules/InputCheckbox';

import inputStyles from '@assets/styles/inputFields.module.scss';
import describeStyles from '@assets/styles/describePain.module.scss';

const symptomsNames = [
  'Heel Pain',
  'Forefoot Pain',
  'Burning Sensation',
  'Bunions',
  'Plantar Fasciitis',
  'Toe Pain',
  'Achilles Tendinitis',
  'Arch Pain',
  'Flat Feet',
];

function InsoleDescribePain(props) {
  const { symptoms, setSymptoms, otherSymptoms, setOtherSymptoms } = props;

  const [showDescribe, setDescribe] = useState(Boolean(otherSymptoms));

  const handleCheckboxChange = (event, symptom) => {
    if (event.target.checked) {
      setSymptoms((prev) => [...prev, symptom]);
    } else {
      setSymptoms((prev) => prev.filter((symp) => symp !== symptom));
    }
  };

  const toggleDescribe = () => {
    setDescribe((prev) => {
      if (prev) {
        setOtherSymptoms('');
      }
      return !prev;
    });
  };

  const PainCheckBox = ({ symptom }) => {
    const id = symptom.split(' ').join('-');

    return (
      <InputCheckbox
        id={id}
        name={symptom}
        checked={symptoms.includes(symptom)}
        onChange={(event) => handleCheckboxChange(event, symptom)}
      />
    );
  };

  return (
    <>
      <div className={describeStyles.insole_describe__wrapper}>
        {symptomsNames.map((symptom) => (
          <PainCheckBox key={symptom} symptom={symptom} />
        ))}

        <label
          htmlFor="other"
          className={cn(describeStyles.insole_describe__pain, {
            [describeStyles.insole_describe__pain_checked]: showDescribe,
          })}
        >
          Other
          <input
            onChange={toggleDescribe}
            id="other"
            type="checkbox"
            className="describe__option"
            checked={true}
          />
        </label>
      </div>
      {showDescribe ? (
        <textarea
          value={otherSymptoms}
          onChange={(event) => setOtherSymptoms(event.target.value)}
          className={inputStyles.input__message}
          placeholder="Describe any other foot pain or issues."
        />
      ) : null}
    </>
  );
}

export default InsoleDescribePain;
