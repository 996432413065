import React from 'react';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ContentContainer } from '../../atoms/ContentContainer';
import { Title } from '../../atoms/Title';
import { ButtonColored } from '../../atoms/buttons';
import { colors, routes, gaEvents } from '../../../constants';
import { AddressBlock, ButtonBlock, TextInput } from '../../form-components';
import { useOrder } from '../../OrderContext';
import { errorHandler } from '../../../helpers/error-handler';
import { triggerScanTool } from '../../../api/scan-triggers';
import { completeOrder } from '../../../api/orders';
import { userconfirmUserInfoSchema } from '../../../validation';
import CircularProgress from '@mui/material/CircularProgress';

import styles from '../../form-components/form.module.scss';

export const ConfirmUserInfo = () => {
  const navigate = useNavigate();
  const { user, order, setOrder, flowType } = useOrder();
  const name = `${order?.firstName} ${order?.lastName}`;

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(userconfirmUserInfoSchema),
    defaultValues: {
      address: order?.address || '',
      city: order?.city || '',
      state: order?.state || '',
      zipCode: order?.zipCode || '',
      apartmentNumber: order?.apartmentNumber || '',
    },
  });

  const handleCancel = () => {
    setOrder(null);
    navigate(routes.home);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { companyCode, uniqId, email, orderId } = order;
      const [firstName, lastName] = data.name.split(' ');
      const payload = {
        ...order,
        ...data,
        firstName,
        lastName,
      };
      const path = `${companyCode}/${email}/${orderId}/`;

      triggerScanTool(companyCode, path, uniqId);

      const orderData = await completeOrder(order._id, payload);

      if(user.lastActive === null) {
        ReactGA.event(gaEvents.createdOrderOnFirstVisit)
      }

      if (flowType === 'withoutPartner') {
        ReactGA.event(gaEvents.soloFlowMode)
      } else {
        ReactGA.event(gaEvents.partnerFlowMode)
      }

      setOrder(null);

      toast.success(`Order ${orderData.orderId} successfully completed`);
      navigate(routes.confirmationOrder);
    } catch (e) {
      errorHandler(e);
    }
  });

  return (
    <ContentContainer>
      <div className="content_centered">
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: '16px' }}>
          <Title>Submit Order</Title>
          <Button sx={{ borderRadius: '10px', p: 0, minWidth: '40px' }} onClick={handleCancel}>
            <CancelOutlinedIcon color="secondary" fontSize="large" />
          </Button>
        </Box>

        <form className={styles.form} onSubmit={onSubmit}>
          <TextInput
            register={register}
            defaultValue={name}
            error={errors.name}
            properties={{ label: 'Full Name', name: 'name' }}
          />

          <Box sx={{ mt: '10px' }}>
            <TextInput
              register={register}
              defaultValue={order?.jobTitle}
              error={errors.jobTitle}
              properties={{ label: 'Job Title', name: 'jobTitle' }}
            />
          </Box>

          <AddressBlock setValue={setValue} watch={watch} errors={errors} />

          <ButtonBlock marginTop="40px">
            <ButtonColored color={colors.black} backgroundColor={colors.yellow} onClick={onSubmit}>
              {isSubmitting ? <CircularProgress color="green" /> : 'Submit'}
            </ButtonColored>
          </ButtonBlock>
        </form>
      </div>
    </ContentContainer>
  );
};
