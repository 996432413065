import { footZones } from '../constants';

export const prepareFootPainMap = (map) => {
  const footPainMap = [];

  for (const zone in map) {
    if (map[zone]) {
      footPainMap.push(zone);
    }
  }

  return footPainMap;
};

const zones = Object.values(footZones);

export const createFootState = (painMap) => {
  const footMap = zones.reduce((map, part) => {
    map[part] = Boolean(painMap?.includes(part));
    return map;
  }, {});

  return footMap;
};
