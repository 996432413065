import { useEffect } from 'react';
import ReactGA from "react-ga4";
import { useOrder } from '../../OrderContext';
import { VideoActionButton } from '../../atoms/buttons/VideoActionButton';
import { colors, gaEvents } from '../../../constants';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { load as loadIntercom, boot as bootIntercom } from '@intercom';

import styles from './VideoActionsBlock.module.scss';
import { useNavigate } from 'react-router-dom';

export const VideoActionsBlock = ({
  footName,
  handleStopCaptureClick,
  handleStartCaptureClick,
  capturing,
  toggleCamera,
}) => {
  const navigate = useNavigate();
  const { flowType } = useOrder();

  useEffect(() => {
    loadIntercom();
    bootIntercom();

    window.Intercom('update', {
      hide_default_launcher: true,
    });

    return () => {
      if (window.Intercom) {
        window.Intercom('update', {
          hide_default_launcher: false,
        });

        window.Intercom('shutdown');
      }
    };
  }, []);

  const openInstructions = () => {
    ReactGA.event(gaEvents.textInstructions)
    const article = flowType === 'withPartner' ? 7026376 : 7026374;
    window.Intercom('showArticle', article);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.actions_block}>
      <div className={styles.flex_block}>
        <h1 className={styles.text}>{footName}</h1>

        <button type="button" className={styles.instructions_button} onClick={openInstructions}>
          <span className={styles.question_mark}>?</span>
          Instructions
        </button>
      </div>

      <div className={styles.flex_block}>
        <VideoActionButton backgroundColor={colors.blue} handleClick={handleBack}>
          <ArrowBackIosNewIcon sx={{ fontSize: 30, color: '#000000' }} />
        </VideoActionButton>

        <VideoActionButton
          backgroundColor={capturing ? colors.pink : colors.blue}
          borderColor={capturing ? colors.darkPink : colors.darkBlue}
          handleClick={capturing ? handleStopCaptureClick : handleStartCaptureClick}
        >
          {capturing ? 'Stop' : 'Start'}
        </VideoActionButton>

        <VideoActionButton backgroundColor={colors.blue} handleClick={toggleCamera}>
          <FlipCameraAndroidIcon sx={{ color: '#000000' }} />
        </VideoActionButton>
      </div>
    </div>
  );
};
