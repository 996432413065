import React from 'react';
import { zoneColors, footZones } from '../../constants';
import styles from '../../assets/styles/pain-map.module.scss';

export const LeftFootMap = ({ onStateChange, leftFoot }) => {
  return (
    <div className={styles.wrapper} style={{ right: '5%' }}>
      <svg
        width="226"
        height="385"
        viewBox="0 0 283 484"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          onClick={() => onStateChange(footZones.bigToe)}
          d="M212.775 90.5834L212.776 90.5848C214.905 94.0438 216.745 96.0273 219.923 98.2644C225.589 102.249 232.134 102.802 238.339 100.385C244.556 97.9641 250.453 92.549 254.734 84.5662C257.612 79.1634 258.793 75.4003 259.91 68.0413C261.022 60.7206 261.011 56.7759 259.873 50.7237C256.855 35.1277 246.638 25.3117 235.014 26.3345C227.14 27.0312 219.121 32.8476 213.865 41.8524C204.989 57.0607 204.563 77.3411 212.775 90.5834Z"
          fill={leftFoot.bigToe ? zoneColors.selected : zoneColors.unselected}
          stroke="black"
        />
        <path
          onClick={() => onStateChange(footZones.innerForeFoot)}
          d="M211.447 125.783C205.524 125.893 199.726 128.746 196.118 133.345C190.032 141.098 190.372 151.826 196.937 159.14C198.644 161.054 200.099 162.183 202.555 163.484C211.377 168.153 222.415 165.551 228.251 157.376C230.212 154.606 230.967 152.642 231.551 148.795C232.132 144.968 231.994 142.869 230.946 139.622C228.191 131.272 220.231 125.624 211.447 125.783Z"
          fill={leftFoot.innerForeFoot ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.foreFoot)}
          d="M154.447 125.783C148.524 125.893 142.726 128.746 139.118 133.345C133.032 141.098 133.372 151.826 139.937 159.14C141.644 161.054 143.099 162.183 145.555 163.484C154.377 168.153 165.415 165.551 171.251 157.376C173.212 154.606 173.967 152.642 174.551 148.795C175.132 144.968 174.994 142.869 173.946 139.622C171.191 131.272 163.231 125.624 154.447 125.783Z"
          fill={leftFoot.foreFoot ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.outerForeFoot)}
          d="M101.447 153.783C95.5239 153.893 89.7262 156.746 86.1175 161.345C80.0323 169.098 80.3723 179.826 86.9375 187.14C88.6443 189.054 90.0993 190.183 92.5554 191.484C101.377 196.153 112.415 193.551 118.251 185.376C120.212 182.606 120.967 180.642 121.551 176.795C122.132 172.968 121.994 170.869 120.946 167.622C118.191 159.272 110.231 153.624 101.447 153.783Z"
          fill={leftFoot.outerForeFoot ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.outerMidFoot)}
          d="M124.447 279.783C118.524 279.893 112.726 282.746 109.118 287.345C103.032 295.098 103.372 305.826 109.937 313.14C111.644 315.054 113.099 316.183 115.555 317.484C124.377 322.153 135.415 319.551 141.251 311.376C143.212 308.606 143.967 306.642 144.551 302.795C145.132 298.968 144.994 296.869 143.946 293.622C141.191 285.272 133.231 279.624 124.447 279.783Z"
          fill={leftFoot.outerMidFoot ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.innerMidFoot)}
          d="M194.447 239.783C188.524 239.893 182.726 242.746 179.118 247.345C173.032 255.098 173.372 265.826 179.937 273.14C181.644 275.054 183.099 276.183 185.555 277.484C194.377 282.153 205.415 279.551 211.251 271.376C213.212 268.606 213.967 266.642 214.551 262.795C215.132 258.968 214.994 256.869 213.946 253.622C211.191 245.272 203.231 239.624 194.447 239.783Z"
          fill={leftFoot.innerMidFoot ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.heel)}
          d="M166.447 401.783C160.524 401.893 154.726 404.746 151.118 409.345C145.032 417.098 145.372 427.826 151.937 435.14C153.644 437.054 155.099 438.183 157.555 439.484C166.377 444.153 177.415 441.551 183.251 433.376C185.212 430.606 185.967 428.642 186.551 424.795C187.132 420.968 186.994 418.869 185.946 415.622C183.191 407.272 175.231 401.624 166.447 401.783Z"
          fill={leftFoot.heel ? zoneColors.selected : zoneColors.unselected}
        />
        <path
          onClick={() => onStateChange(footZones.fourthToe)}
          d="M165.213 50.3931L165.213 50.3933C164.479 52.0357 163.701 55.4807 163.166 59.0384C162.63 62.5955 162.355 66.1463 162.58 67.9926L162.58 67.9942C163.623 76.8266 167.493 84.1557 173.004 87.9881C174.113 88.7528 174.721 89.1341 175.477 89.4032C176.249 89.6776 177.193 89.842 178.991 90.1151C180.771 90.3852 181.721 90.5085 182.539 90.4755C183.341 90.4431 184.035 90.2595 185.3 89.8561L185.3 89.8561L185.304 89.8547C192.247 87.7105 197.822 80.3472 199.819 70.4892C201.537 61.9585 200.125 53.5448 196.721 47.3002C193.314 41.0507 187.958 37.0395 181.794 37.1207C175.315 37.209 168.818 42.3242 165.213 50.3931Z"
          fill={leftFoot.fourthToe ? zoneColors.selected : zoneColors.unselected}
          stroke="black"
        />
        <path
          onClick={() => onStateChange(footZones.thirdToe)}
          d="M134.146 47.5114L134.146 47.5115L134.136 47.5138C131.314 48.1825 129.616 48.9667 127.489 50.7076L127.488 50.7088C123.473 53.9688 120.786 59.311 119.718 65.4542C118.651 71.5915 119.209 78.4819 121.634 84.7847C122.46 86.9138 123.846 89.3342 125.41 91.5033C126.976 93.6748 128.692 95.5564 130.166 96.6359C131.349 97.4607 133.333 98.5974 134.581 99.1243L134.582 99.1245C142.236 102.362 150.714 98.0501 154.992 88.4201C155.651 86.9231 156.298 84.2136 156.743 81.3502C157.187 78.4918 157.417 75.5537 157.274 73.614C156.513 63.8163 151.772 54.507 145.39 50.2168L145.387 50.2152C144.15 49.3721 143.379 48.9076 142.554 48.573C141.726 48.2372 140.823 48.0237 139.301 47.7153C136.582 47.1872 135.544 47.1502 134.146 47.5114Z"
          fill={leftFoot.thirdToe ? zoneColors.selected : zoneColors.unselected}
          stroke="black"
        />
        <path
          onClick={() => onStateChange(footZones.secondToe)}
          d="M84.1438 80.3439L84.1436 80.3449C82.9947 85.2784 83.3971 90.9676 85.0223 96.2469C86.6477 101.527 89.4827 106.354 93.1518 109.582L93.1519 109.582L93.1541 109.584C98.6331 114.464 105.286 115.244 110.469 111.761C112.81 110.172 114.18 108.561 115.863 105.275C116.889 103.218 117.209 102.125 117.771 98.1688C118.333 94.2138 118.372 92.9549 117.934 90.083L117.933 90.0779C116.615 80.7965 112.374 73.4255 106.095 69.2514L106.095 69.2514L106.089 69.2478C105.079 68.5571 104.395 68.1536 103.618 67.8546C102.836 67.5532 101.937 67.3493 100.483 67.0898L100.482 67.0895C97.9188 66.6231 96.7781 66.5719 95.6387 66.8569C89.9565 68.3438 85.8163 73.099 84.1438 80.3439Z"
          fill={leftFoot.secondToe ? zoneColors.selected : zoneColors.unselected}
          stroke="black"
        />
        <path
          d="M88.3176 232.17C92.271 249.165 95.1127 265.831 98.2944 290.923L98.2945 290.923C100.488 308.315 100.88 315.419 101.302 346.722L100.802 346.729L101.302 346.722C101.878 389.739 102.722 404.472 105.264 415.544C108.059 427.675 114.732 440.022 123.438 450.355C132.145 460.691 142.857 468.978 153.7 473.027C157.865 474.574 164.415 475.496 170.185 475.333L170.186 475.333C184.987 474.964 196.337 470.083 205.57 460.086L205.572 460.084C214.889 450.089 219.906 438.082 222.834 418.8C224.247 409.493 224.771 402.875 224.013 394.409C223.254 385.926 221.208 375.576 217.46 358.806C214.506 345.6 212.933 338.216 212.202 332.105C211.47 325.98 211.583 321.141 211.984 313.031C212.506 301.957 213.221 294.948 215.009 283.178C216.816 271.018 218.036 265.284 221.002 254.924C223.172 247.267 224.473 243.83 229.294 233.218L229.294 233.218C236.695 216.957 239.019 210.009 244.041 189.381L244.041 189.38C245.869 181.913 248.601 167.22 249.206 161.714C250.665 148.3 250.372 139.569 248.271 132.592L248.27 132.589C246.959 128.154 245.178 124.555 242.45 121.181C239.717 117.8 236.019 114.625 230.849 111.057L230.847 111.056C225.742 107.503 221.638 105.347 216.915 103.786L216.91 103.784C214.911 103.1 211.181 102.263 207.294 101.556C203.411 100.85 199.419 100.284 196.904 100.132C171.969 98.6267 140.725 104.793 116.972 115.962C97.2794 125.234 84.8478 135.724 77.2022 149.524C71.5528 159.748 69.4278 169.72 70.4546 181.105C71.3465 190.847 74.1505 199.048 82.1763 214.757L82.1766 214.758C84.0479 218.432 85.0465 220.444 85.8448 222.714C86.6414 224.98 87.2356 227.495 88.3176 232.17ZM88.3176 232.17L87.8306 232.284L88.3177 232.171C88.3177 232.171 88.3177 232.171 88.3176 232.17Z"
          stroke="black"
        />
        <path
          onClick={() => onStateChange(footZones.pinkieToe)}
          d="M61.9173 93.2284L61.9173 93.2285L61.9094 93.2304C56.7977 94.4802 53.6927 99.1928 52.9975 105.223C52.3034 111.245 54.0409 118.47 58.5133 124.488C62.1767 129.403 66.1036 131.909 70.7951 132.32C72.4816 132.461 73.7643 132.389 74.9201 132.052C76.0726 131.716 77.1367 131.105 78.3686 130.11C80.8386 128.051 82.2509 125.377 82.9194 121.222L82.9194 121.221C83.5612 117.242 83.3952 114.664 82.2736 110.638L82.2732 110.637C80.5243 104.274 77.1805 99.314 72.4144 95.9059L72.411 95.9034C71.1526 94.9874 70.3901 94.483 69.618 94.1364C68.85 93.7916 68.0497 93.5929 66.6962 93.3288L66.6934 93.3282C64.3564 92.8578 63.3314 92.858 61.9173 93.2284Z"
          fill={leftFoot.pinkieToe ? zoneColors.selected : zoneColors.unselected}
          stroke="black"
        />
      </svg>
    </div>
  );
};
