import React, { useState } from 'react';
import Box from '@mui/material/Box';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { fieldTypes } from '@constants';

import styles from './form.module.scss';
import errorsStyles from '../../assets/styles/errors.module.scss';

export const TextInput = ({ register, defaultValue, error, properties }) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown((prev) => !prev);
  };

  return (
    <label className={styles.text_field}>
      <span className={styles.text_label}>{properties.label}</span>
      <input
        id={properties.name}
        {...register(properties.name)}
        type={passwordShown ? fieldTypes.TEXT : properties.type}
        className={styles.input}
        placeholder={properties.placeholder ? properties.placeholder : properties.label}
        defaultValue={defaultValue || ''}
      />

      {(properties.name === fieldTypes.PASSWORD ||
        properties.name === fieldTypes.REPEAT_PASSWORD) && (
        <Box sx={{ position: 'absolute', top: '60px', right: '16px' }}>
          {passwordShown ? (
            <VisibilityIcon className={styles.password__icon} onClick={togglePassword} />
          ) : (
            <VisibilityOff className={styles.password__icon} onClick={togglePassword} />
          )}
        </Box>
      )}

      {error && (
        <div className={errorsStyles.field_error}>{error?.message}</div>
      )}
    </label>
  );
};
