import toast from "react-hot-toast";

export const errorHandler = (errors) => {
  console.log(errors)
  if (!Array.isArray(errors)) {
    console.error("Errors must be an array.");
    return;
  }

  if (errors.length === 0) {
    console.warn("Errors array is empty.");
    return;
  }

  errors.forEach((error) => {
    toast.error(error, { duration: 7000 });
  });
};
