import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import Alert from '@mui/material/Alert';
import YouTube from 'react-youtube';
import { load as loadIntercom, boot as bootIntercom } from '@intercom';
import { ContentContainer } from '../../atoms/ContentContainer';
import { ButtonColored } from '../../atoms/buttons';
import { Title } from '../../atoms/Title';
import { useOrder } from '../../OrderContext';
import { colors, routes, gaEvents } from '../../../constants';

import tutorialStyles from '@assets/styles/OrderStyles/tutorial.module.scss';

const VIDEO_IDS = {
  withPartner: 'odaR-bf2RdY',
  withoutPartner: 'vLfy01Gd1-I',
};

export const VideoTutorial = () => {
  const navigate = useNavigate();
  const { flowType } = useOrder();
  const videoId = VIDEO_IDS[flowType];
  const [watchedVideo, setWatchedVideo] = useState(true);
  const [openAlert, setOpenAlert] = useState(true);
  const [timeSpentOnPage, setTimeSpentOnPage] = useState(0);


  const opts = {
    height: '220',
    width: '100%',
  };

  const handleNext = () => {
    if (!watchedVideo) {
      setOpenAlert(true)
    } else {
      navigate(routes.scanningLeftFoot);
    }
  };

  const handleOnEnd = (event) => {
    setWatchedVideo(true);
    setOpenAlert(false);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeSpentOnPage(prevTime => prevTime + 1000);
    }, 1000);
    return () => {
      ReactGA.event({...gaEvents.timeSpentOnVideoWatchingScreen.label, value: timeSpentOnPage, metric: {timeSpend: timeSpentOnPage}});
      clearInterval(intervalId)
    };
  }, []);

  useEffect(() => {
    loadIntercom();
    bootIntercom();

    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, []);

  return (
    <ContentContainer>
      <div className={tutorialStyles.tutorial}>
        <Title>Watch tutorial</Title>
        <p className={tutorialStyles.tutorial_paragraph}>
          To better assist you we have created a tutorial
        </p>

        {openAlert && (
          <Alert sx={{ mt: '15px' }} severity="info">
            To continue, you're required to watch through the full instructions tutorial.
          </Alert>
        )}

        <div className={tutorialStyles.tutorial_player}>
          <YouTube videoId={videoId} opts={opts} onEnd={handleOnEnd} />
        </div>

        <div className={tutorialStyles.tutorial_button_block}>
          <ButtonColored color={colors.black} backgroundColor={watchedVideo ? colors.yellow : colors.darkGray} onClick={handleNext}>
            Proceed
          </ButtonColored>
        </div>
      </div>
    </ContentContainer>
  );
};
