import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import NumberRating from '@components/atoms/NumberRating';
import { useOrder } from '@components/OrderContext';
import { postCustomerReview } from '@api/reviews';
import { errorHandler } from '@helpers/error-handler';
import toast from 'react-hot-toast';

import formStyles from '@assets/styles/forms.module.scss';
import buttonStyles from '@assets/styles/buttons.module.scss';
import feedbackStyles from '@assets/styles/feedback.module.scss';
import CircularProgress from '@mui/material/CircularProgress';

function CustomerReview() {
  const { user } = useOrder();
  const methods = useForm();
  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await postCustomerReview({ userId: user._id, ...data });

      toast.success('Thank you for the feedback');
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <FormProvider {...methods}>
      <section id="customer-review">
        <div className="container">
          <div className="row">
            <form onSubmit={methods.handleSubmit(onSubmit)} className={formStyles.form__wrapper}>
              <h1 className="section__title">Customer Review</h1>
              <p className={feedbackStyles.feedback__para}>
                Have you ordered orthotics in the past?
              </p>
              <label htmlFor="past-insoles-yes">
                <input
                  id="past-insoles-yes"
                  type="radio"
                  value="yes"
                  className={feedbackStyles.past_insoles_yes}
                  {...methods.register('pastInsoles')}
                />
                Yes
              </label>
              <label htmlFor="past-insoles-no">
                <input
                  id="past-insoles-no"
                  type="radio"
                  value="no"
                  className={feedbackStyles.past_insoles_no}
                  {...methods.register('pastInsoles')}
                />
                No
              </label>
              <p className={feedbackStyles.feedback__para}>
                How would you rate your experience ordering with Hike?
              </p>
              <NumberRating name="experienceOrdering" />
              <p className={feedbackStyles.feedback__para}>
                How likely are you to recommend us to a friend?
              </p>
              <NumberRating name="recommendFriend" />
              <p className={feedbackStyles.feedback__para}>Additional Feedback</p>
              <textarea
                name="additionalReview"
                {...methods.register('additionalReview')}
                className={feedbackStyles.feedback__text}
                placeholder="Your Message..."
              />
              <button className={buttonStyles.next__button} type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress color="green" /> : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </section>
    </FormProvider>
  );
}

export default CustomerReview;
