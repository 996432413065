import React, { useState } from 'react';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InsoleDescribePain from '@components/organisms/OrderComponents/InsoleDescribePain';
import { ButtonColored } from '@components/atoms/buttons';
import { routes, insoleButton, insoleFormRadioButton, colors, footSide, gaEvents } from '@constants';
import { useOrder } from '@components/OrderContext';

import formStyles from '@assets/styles/forms.module.scss';

function InsoleFormFoot() {
  const navigate = useNavigate();
  const { order, setOrder } = useOrder();
  const [toggleFoot, setToggleFoot] = useState(footSide.LEFT);
  const [symptoms, setSymptoms] = useState(order?.leftFootSymptoms || []);
  const [symptomsRight, setSymptomsRight] = useState(order?.rightFootSymptoms || []);
  const [otherSymptoms, setOtherSymptoms] = useState(order?.description || '');
  const [otherRightSymptoms, setOtherRightSymptoms] = useState(order?.rightDescription || '');

  const onSubmit = (event) => {
    event.preventDefault();

    setOrder((prev) => ({
      ...prev,
      leftFootSymptoms: symptoms,
      description: otherSymptoms,
      rightFootSymptoms: symptomsRight,
      rightDescription: otherRightSymptoms,
    }));

    toggleFoot !== footSide.LEFT ? navigate(routes.haveDiabetes) : setToggleFoot(footSide.RIGHT);
  };

  const handleApplyBoth = () => {
    ReactGA.event(gaEvents.applyToBothFeet);
    
    setOrder((prev) => ({
      ...prev,
      leftFootSymptoms: symptoms,
      description: otherSymptoms,
      rightFootSymptoms: symptoms,
      rightDescription: otherSymptoms,
    }));
    setSymptomsRight(symptoms);
    setOtherRightSymptoms(otherSymptoms);

    setToggleFoot(footSide.RIGHT);
  };

  const handleToggleFoot = (event) => {
    setToggleFoot(event.target.value);
  };

  return (
    <section id="insole-form">
      <div className="container">
        <div className="row">
          <form onSubmit={onSubmit} className={formStyles.form__wrapper}>
            <h1 className={formStyles.form__title}>Any current discomfort in your</h1>

            <Stack
              direction="row"
              spacing={1}
              sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', mb: '30px' }}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={toggleFoot}
                  onChange={handleToggleFoot}
                  sx={{
                    display: 'flex',
                    border: '1px solid #000000',
                    borderRadius: '28px',
                    flexDirection: 'row',
                    '& .MuiButtonBase-root .css-hyxlzm': {
                      display: 'none'
                    },
                    '& .MuiButtonBase-root': {
                      p: 0
                    }
                  }}
                >
                  <FormControlLabel
                    sx={{
                      ...insoleFormRadioButton,
                      backgroundColor: toggleFoot === 'left' ? 'rgba(189, 224, 254, 1)' : '#ffffff',
                      borderTopRightRadius: toggleFoot === 'left' ? '28px' : 0,
                      borderBottomRightRadius: toggleFoot === 'left' ? '28px' : 0,
                      borderRight: toggleFoot === 'left' ? '1px solid #000000' : 0,
                      borderTopLeftRadius: '28px',
                      borderBottomLeftRadius: '28px',
                    }}
                    value={footSide.LEFT}
                    control={<Radio />}
                    label="Left Foot"
                  />
                  <FormControlLabel
                    sx={{
                      ...insoleFormRadioButton,
                      backgroundColor: toggleFoot === 'right' ? 'rgba(189, 224, 254, 1)' : '#ffffff',
                      borderTopLeftRadius: toggleFoot === 'right' ? '28px' : 0,
                      borderBottomLeftRadius: toggleFoot === 'right' ? '28px' : 0,
                      borderLeft: toggleFoot === 'right' ? '1px solid #000000' : 0,
                      borderTopRightRadius: '28px',
                      borderBottomRightRadius: '28px',
                    }}
                    value={footSide.RIGHT}
                    control={<Radio />}
                    label="Right Foot"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>

            {toggleFoot === footSide.LEFT ? (
              <InsoleDescribePain
                symptoms={symptoms}
                setSymptoms={setSymptoms}
                otherSymptoms={otherSymptoms}
                setOtherSymptoms={setOtherSymptoms}
              />
            ) : (
              <InsoleDescribePain
                symptoms={symptomsRight}
                setSymptoms={setSymptomsRight}
                otherSymptoms={otherRightSymptoms}
                setOtherSymptoms={setOtherRightSymptoms}
              />
            )}

            <Stack
              direction="row"
              spacing={2}
              sx={{
                width: '100%',
                mt: '30px',
                display: 'flex',
                justifyContent: `${toggleFoot === footSide.LEFT ? 'space-between' : 'flex-end'}`
              }}
            >
              {toggleFoot === footSide.LEFT && (
                <ButtonColored
                  onClick={handleApplyBoth}
                  color={colors.white}
                  backgroundColor={colors.green}
                  width={'262px'}
                  sx={insoleButton}
                  disabled={symptoms.length === 0 && otherSymptoms === '' ? true : false}
                >
                  Apply to both feet
                </ButtonColored>
              )}
              <Button
                variant="outlined"
                type="submit"
                onClick={onSubmit}
                sx={{
                  height: '56px',
                  borderRadius: '12px',
                  color: '#006875',
                  border: '0.5px solid #006875',
                  '&:hover': {
                    border: '0.5px solid #006875',
                  }
                }}
              >
                <ArrowForwardIosIcon sx={{ color: '#006875' }} />
              </Button>
            </Stack>
          </form>
        </div>
      </div>
    </section>
  );
}

export default InsoleFormFoot;
