import React, { useState } from 'react';
import cn from 'classnames';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Picker from 'rmc-picker-scroll';
import { useOrder } from '@components/OrderContext';
import {
  shoeInfoSelectTextDown,
  shoeInfoSmallSelectTextDown,
  shoeInfoSelectContainer,
  shoeInfoSelectInput,
  shoeInfoWidthSelectText,
  shoeInfoSizeSelectText
} from '@constants';
import 'rmc-picker-scroll/assets/index.css';
import styles from '../../../assets/styles/OrderStyles/shoeInfoSelect.module.scss';

function ShoeInfoSelect({ heightBox, options = [], label, defaultValue, type, error = false }) {
  const { order, setOrder } = useOrder();
  const [currentValue, setCurrentValue] = useState(order?.[type] || defaultValue);
  const [brandName, setBrandName] = useState(order?.[type] || defaultValue);

  const handleChange = (value) => {
    setOrder((prev) => ({
      ...prev,
      [type]: value,
    }));

    setCurrentValue(value)
  };

  const handleInputChange = (event) => {
    setOrder((prev) => ({
      ...prev,
      [type]: event.target.value,
    }));

    setBrandName(event.target.value)
  };

  return (
    <Box
        className={type}
        sx={shoeInfoSelectContainer}
      >
        {type === 'shoeBrand' ? (
          <Box
            sx={{
              height: `${heightBox}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <input
              type="text"
              onChange={handleInputChange}
              defaultValue={brandName}
              placeholder={`${error ? '* ' : ''}Brand`}
              className={cn(styles.shoeInfo_input_brand, { [styles.input_error]: error })}
              style={shoeInfoSelectInput}
            />
          </Box>
        ) : (
          <Picker
            selectedValue={currentValue}
            onValueChange={handleChange}
            onScrollChange={handleChange}
          >
            {options && options.map((option) => (
              <Picker.Item value={option} key={option}>
                {option}
              </Picker.Item>
            ))}
          </Picker>
        )}

        {type !== 'shoeBrand' && (
          <Box sx={type !== 'shoeSize' ? shoeInfoSmallSelectTextDown : shoeInfoSelectTextDown}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={type !== 'shoeSize' ? shoeInfoWidthSelectText : shoeInfoSizeSelectText}
            >
              {label}
            </Typography>
          </Box>
        )}

      </Box>
  );
};

export default ShoeInfoSelect;
