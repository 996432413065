import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: 'us-east-2',
  signatureVersion: 'v4',
});

const s3 = new AWS.S3();

export const uploadFileToAWS = async (key, body, additionalParams = {}, throwOnError = false) => {
  if (process.env.NODE_ENV !== 'production') {
    return Promise.resolve({ Key: 'guimabonini@gmail.com/123123123123Left.mp4' });
  }

  // Create a params object that includes the bucket, key, body, and any additional parameters passed in.
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: key,
    Body: body,
    ...additionalParams,
  };

  try {
    const response = await s3.upload(params).promise();

    return response;
  } catch (error) {
    if (throwOnError) {
      throw error;
    } else {
      console.log(error);
    }
  }
};
