import styles from './buttons.module.scss';

export const VideoActionButton = ({ backgroundColor, borderColor, handleClick, children }) => {
  return (
    <button
      type="button"
      onClick={handleClick}
      className={styles.video_action_button}
      style={{ backgroundColor, borderColor }}
    >
      {children}
    </button>
  );
};
