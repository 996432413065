export const userInfo = {
    weightProps: {
        name: 'weight',
        options: [
            {value: '0-100', label: '0-100'},
            {value: '101-150', label: '101-150'},
            {value: '151-200', label: '151-200'},
            {value: '201-250', label: '201-250'},
            {value: '251-300', label: '251-300'},
            {value: '301-350', label: '301-350'},
            {value: '351-400', label: '351-400'}
        ],
        label: 'Weight',
        disabledOption: 'Weight (LBS)'
    },
    genderProps: {
        name: 'gender',
        options: [
            {value: 'male', label: 'Male'},
            {value:'female', label:'Female'}
        ],
        label: 'Biological Sex',
        disabledOption: 'Select your gender'
    },
    feetProps: {
        name: 'heightFeet',
        label: 'Height',
        disabledOption: 'Feet'
    },
    inchesProps: {
        name: 'heightInches',
        label: 'Height',
        disabledOption: 'Inches'
    }
}