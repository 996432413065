import $api, { ENDPOINTS } from './index';
import { getItemFromLocalStorage } from '../helpers/localstorage-helper';
import axios from 'axios';

export const registration = (payload) => {
  return $api.post(ENDPOINTS.registration, payload);
};

export const login = (payload) => {
  return $api.post(ENDPOINTS.login, payload);
};

export const forgotPassword = (payload) => {
  return $api.post(ENDPOINTS.forgotPassword, payload);
};

export const createNewPassword = (payload) => {
  return $api.post(ENDPOINTS.createNewPassword, payload);
};

export const verifyCode = (payload) => {
  return $api.post(ENDPOINTS.verifyCode, payload);
};

export const logout = () => {
  const refreshToken = getItemFromLocalStorage('tokens')?.refreshToken;
  return $api.post(ENDPOINTS.logout, { refreshToken })
}

export const refresh = () => {
  const refreshToken = getItemFromLocalStorage('tokens')?.refreshToken;

  return axios.post(ENDPOINTS.refresh, { refreshToken });
};
