import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrder } from '@components/OrderContext';
import { ContentContainer } from '../../atoms/ContentContainer';
import { SectionWrapper } from '../../atoms/SectionWrapper';
import { ButtonColored } from '../../atoms/buttons';
import { FootPainParagraph } from '../../atoms/FootPainParagraph';
import { ButtonsWrapper } from '../../atoms/ButtonsWrapper';
import { createFootState, prepareFootPainMap } from '../../../helpers';
import { routes, painMaps, colors } from '../../../constants';
import { LeftFootMap } from '../../atoms/LeftFootMap';

function LeftPainMap() {
  const { order, setOrder } = useOrder();
  const navigate = useNavigate();

  const leftMap = createFootState(order?.[painMaps.left]);
  const [leftFoot, setLeftFoot] = useState(leftMap);
  const leftFootPainMap = prepareFootPainMap(leftFoot);
  const isFootPain = leftFootPainMap.length > 0;

  const handleLeftStateChange = (zone) => {
    setLeftFoot((prev) => ({
      ...prev,
      [zone]: !prev[zone],
    }));
  };

  const handlePainMapSubmit = () => {
    setOrder((prev) => ({
      ...prev,
      leftFootPainMap,
    }));

    navigate(routes.rightPainMap);
  };

  return (
    <ContentContainer>
      <SectionWrapper>
        <LeftFootMap onStateChange={handleLeftStateChange} leftFoot={leftFoot} />

        <FootPainParagraph foot="Left" />

        <ButtonsWrapper>
          <ButtonColored
            width="100%"
            color={isFootPain ? colors.white : colors.black}
            backgroundColor={isFootPain ? colors.green : colors.blue}
            fontWeight={isFootPain ? '600' : '400'}
            onClick={handlePainMapSubmit}
          >
            {isFootPain ? 'Next' : 'No Pain'}
          </ButtonColored>
        </ButtonsWrapper>
      </SectionWrapper>
    </ContentContainer>
  );
}

export default LeftPainMap;
