export const feet = {
  left: 'left',
  right: 'right',
};

export const footZones = {
  pinkieToe: 'pinkieToe',
  fourthToe: 'fourthToe',
  thirdToe: 'thirdToe',
  secondToe: 'secondToe',
  bigToe: 'bigToe',
  foreFoot: 'foreFoot',
  outerForeFoot: 'outerForeFoot',
  innerForeFoot: 'innerForeFoot',
  outerMidFoot: 'outerMidFoot',
  innerMidFoot: 'innerMidFoot',
  heelMidFoot: 'heelMidFoot',
  heel: 'heel',
};

export const painMaps = {
  left: 'leftFootPainMap',
  right: 'rightFootPainMap',
};

export const zoneColors = {
  red: '#FF0000',
  white: 'white',
  toe: '#60A9FF',
  selected: '#FFD8E4',
  unselected: '#EFF5F6',
};
