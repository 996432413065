import React from 'react';
import { Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import TermsOfService from '@assets/TermsOfServiceHikeMedical.pdf';

import styles from './form.module.scss';

export const TermsCheckBox = ({ defaultChecked, register }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          defaultChecked={defaultChecked}
          {...register('isTerms')}
          sx={{
            color: '#D9D9D9',
            '&.Mui-checked': {
              color: 'rgb(0, 104, 117)',
            },
          }}
        />
      }
      label={
        <Link className={styles.terms_label} to={TermsOfService} target="_blank">
          I agree to Hike medicals terms and conditions
        </Link>
      }
    />
  );
};
