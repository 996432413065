import { useState } from 'react';
import { createFileName } from '../../helpers/create-file-name';
import { uploadFileToAWS } from '../../api/AWS-service';
import toast from 'react-hot-toast';
import { errorHandler } from '../../helpers/error-handler';
import { VideoRecording } from '../../components/organisms/OrderComponents/VideoRecording';
import { validateVideo } from '../../api/scan-triggers';
import { dummyOrder } from '../../constants/dummy-order';
import { mainContainer } from '../../constants';
import Box from '@mui/material/Box';

export const TestRejection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [blob, setBlob] = useState(null);

  const saveFile = async () => {
    setIsLoading(true);

    try {
      const fileName = createFileName(dummyOrder, `Left.mp4`);

      const { Key } = await uploadFileToAWS(fileName, blob);
      await validateVideo(Key);

      toast.success('Video successfully uploaded');
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={mainContainer}>
      <VideoRecording
        footName="Left Foot"
        setBlob={setBlob}
        onFileSave={saveFile}
        isLoading={isLoading}
      />
    </Box>
  );
};
