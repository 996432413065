export const routes = {
  dev: '/dev',
  home: '/',
  register: '/register',
  login: '/login',
  forgotPassword: '/forgot-password',
  createPassword: '/create-password',
  configureAccount: '/configure-account',
  feedback: '/feedback',
  createNewOrder: '/create-new-order',
  customerReview: '/customer-review',
  support: '/support',
  insoleForm: '/insole-form',
  haveDiabetes: '/have-diabetes',
  shoeInfo: '/shoe-info',
  leftPainMap: '/left-pain-map',
  rightPainMap: '/right-pain-map',
  videoTutorial: '/video-tutorial',
  scanningLeftFoot: '/scanning-left-foot',
  scanningRightFoot: '/scanning-right-foot',
  confirmUserInfo: '/confirm-user-info',
  confirmationOrder: '/confirmation-order',
};
