import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { userOrderInfo } from '@constants';

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function PhoneInputField({ setValue, watch, error }) {
  const phoneNumberValue = watch(userOrderInfo.PHONE_NUMBER);

  const handleChange = (event) => {
    setValue(userOrderInfo.PHONE_NUMBER, event.target.value)
  };

  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          color: '#000',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '48px',
          letterSpacing: '0.15px'
        }}
      >
        Primary contact number
      </Typography>
      <FormControl
        variant="standard"
        sx={{
          m: 0,
          width: '100%',
          '.MuiInputBase-root .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before, .MuiInputBase-root .MuiInput-root:focus': {
            display: 'none'
          },
          '.MuiInputBase-root.MuiInput-root:before, .MuiInputBase-root.MuiInput-root:after': {
            display: 'none'
          }
        }}>
        <Input
          sx={{
            border: '0.5px solid #6F797B',
            borderRadius: '24px',
            p: 0,
            '.MuiInputBase-input': {
              width: '100%',
              height: '50px',
              p: '0 18px',
              fontWeight: 300,
              fontSize: '16px',
              letterSpacing: '0.15px',
              borderRadius: '24px',
            }
          }}
          value={phoneNumberValue}
          onChange={handleChange}
          placeholder="(000) 000-0000"
          name={userOrderInfo.PHONE_NUMBER}
          inputComponent={TextMaskCustom}
        />
      </FormControl>

      {error && (
        <Typography
          variant="body2"
          sx={{
            color: '#F45532',
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '12px',
            letterSpacing: '0.15px',
            mt: '10px',
            ml: '12px'
          }}
        >
          {error.message}
        </Typography>
      )}

    </Box>
  );
};

export default PhoneInputField;
