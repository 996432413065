import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const CustomMuiSelect = ({ setValue, defaultValue, width, weightProps, hasLabel = true, error }) => {
  const [currentValue, setCurrentValue] = useState(defaultValue || '');
  const handleChange = (event) => {
    setValue(weightProps.name, event.target.value)
    setCurrentValue(event.target.value)
  }

  return (
    <Box sx={{ width: width }}>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '48px',
          letterSpacing: '0.15px',
          color: '#000000',
          opacity: `${hasLabel ? 1 : 0}`
        }}
      >
        {weightProps.label}
      </Typography>

      <FormControl fullWidth>
        <Select
          onChange={handleChange}
          value={currentValue}
          name={weightProps.name}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            borderRadius: '24px',
            height: '50px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: '0.5px solid #6F797B',
              borderRadius: '24px',
            },
          }}
        >
          <MenuItem value="" disabled>
            {weightProps.disabledOption}
          </MenuItem>
          {weightProps.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {error && (
        <Typography
          variant="body2"
          sx={{
            color: '#F45532',
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '12px',
            letterSpacing: '0.15px',
            mt: '10px',
            ml: '12px'
          }}
        >
          {error.message}
        </Typography>
      )}
    </Box>
  );
};
