import React from 'react';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';

import styles from './form.module.scss';

export const DateOfBirth = ({ value, setValue, error }) => {
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);
  const maxDateString = maxDate.toISOString().slice(0, 10);

  return (
    <label className={styles.text_field}>
      <span className={styles.text_label}>Birthday</span>

      <DatePicker
        value={dayjs(value)}
        onChange={(newValue) => setValue('dateOfBirth', newValue)}
        minDate={dayjs('1900-01-01')}
        maxDate={dayjs(maxDateString)}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '50px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '0.5px solid #6F797B',
            borderRadius: '24px',
          },
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: '0.5px solid #6F797B',
          }
        }}
      />
      {error && (
        <Typography
          variant="body2"
          sx={{
            color: '#F45532',
            fontWeight: 400,
            fontSize: '10px',
            lineHeight: '12px',
            letterSpacing: '0.15px',
            mt: '10px',
            ml: '12px'
          }}
        >
          {error.message}
        </Typography>
      )}
    </label>
  );
};
