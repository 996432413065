import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useOrder } from '../../OrderContext';
import { errorHandler } from '../../../helpers/error-handler';
import { createFileName } from '../../../helpers/create-file-name';
import { uploadFileToAWS } from '../../../api/AWS-service';
import { VideoRecording } from './VideoRecording';
import { routes } from '../../../constants';

function showMobileDeviceAlert() {
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobileDevice) {
    alert('Please turn on your portrait mode lock or screen lock before recording.');
    window.localStorage.setItem('mobileDeviceAlertShown', 'true');
  }
}

export const ScanningLeftFoot = () => {
  const navigate = useNavigate();
  const { order, setOrder } = useOrder();
  const [isLoading, setIsLoading] = useState(false);
  const [blob, setBlob] = useState(null);

  const mobileDeviceAlertShown = window.localStorage.getItem('mobileDeviceAlertShown');

  if (!mobileDeviceAlertShown) {
    showMobileDeviceAlert();
  }

  const saveFile = async () => {
    setIsLoading(true);

    try {
      const fileName = createFileName(order, 'LEFT.mp4');

      const { Key } = await uploadFileToAWS(fileName, blob);
      setOrder((prev) => ({
        ...prev,
        leftVideoPath: Key,
      }));

      // await validateVideo(Key);

      toast.success('Video successfully uploaded');
      navigate(routes.scanningRightFoot);
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VideoRecording
      footName="Left Foot"
      setBlob={setBlob}
      onFileSave={saveFile}
      isLoading={isLoading}
    />
  );
};
