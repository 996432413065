// Set your APP_ID
export const APP_ID = 'q439zdc6';

// Loads Intercom
// This must be run before boot, it initializes window.Intercom
// Define a function called "load" and export it.
export const load = () => {
  // Define an anonymous function and immediately invoke it.
  (function () {
    // Assign the window object to a variable called "w".
    var w = window;
    // Assign the Intercom function to a variable called "ic".
    var ic = w.Intercom;
    // If Intercom is a function, reattach the activator and update intercomSettings with the new settings.
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    }
    // If Intercom is not a function, initialize it.
    else {
      // Assign the document object to a variable called "d".
      var d = document;
      // Define a function called "i".
      var i = function () {
        i.c(arguments);
      };
      // Initialize a queue called "i.q" as an empty array.
      i.q = [];
      // Define a function called "i.c" that pushes its arguments into "i.q".
      i.c = function (args) {
        i.q.push(args);
      };
      // Assign the "i" function to the Intercom global variable.
      w.Intercom = i;
      // Define a function called "l" that will load the Intercom script.
      var l = function () {
        // Create a new script element and assign it to a variable called "s".
        var s = d.createElement('script');
        // Set the type attribute of the script element to "text/javascript".
        s.type = 'text/javascript';
        // Set the async attribute of the script element to "true".
        s.async = true;
        // Set the src attribute of the script element to the Intercom widget URL with the APP_ID variable.
        s.src = 'https://widget.intercom.io/widget/' + APP_ID;
        // Get the first script element in the document and assign it to a variable called "x".
        var x = d.getElementsByTagName('script')[0];
        // Insert the new script element before the first script element.
        x.parentNode.insertBefore(s, x);
      };
      // If the document has already loaded, call the "l" function immediately.
      if (document.readyState === 'complete') {
        l();
      }
      // If the document has not yet loaded and the window has an attachEvent method, attach the "l" function to the "onload" event.
      else if (w.attachEvent) {
        w.attachEvent('onload', l);
      }
      // If the document has not yet loaded and the window has an addEventListener method, add an event listener for the "load" event and call the "l" function when it fires.
      else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};
// Initializes Intercom
export const boot = (options = {}) => {
  window && window.Intercom && window.Intercom('boot', { app_id: APP_ID, ...options });
};

// Updates Intercom on every page change
export const update = () => {
  window && window.Intercom && window.Intercom('update');
};
