import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useOrder } from '@components/OrderContext';
import { ContentContainer } from '../../atoms/ContentContainer';
import { SectionWrapper } from '../../atoms/SectionWrapper';
import { ButtonColored } from '../../atoms/buttons';
import { FootPainParagraph } from '../../atoms/FootPainParagraph';
import { ButtonsWrapper } from '../../atoms/ButtonsWrapper';
import { createFootState, prepareFootPainMap } from '../../../helpers';
import { routes, painMaps, colors } from '../../../constants';
import { createOrder } from '../../../api/orders';
import { errorHandler } from '../../../helpers/error-handler';
import { RightFootMap } from '../../atoms/RightFootMap';

function RightPainMap() {
  const { order, setOrder } = useOrder();
  const navigate = useNavigate();

  const rightMap = createFootState(order?.[painMaps.right]);
  const [rightFoot, setRightFoot] = useState(rightMap);
  const [isLoading, setIsLoading] = useState(false);
  const rightFootPainMap = prepareFootPainMap(rightFoot);
  const isFootPain = rightFootPainMap.length > 0;

  const handleOrderSubmit = async () => {
    if (order.orderId) {
      setOrder((prev) => ({
        ...prev,
        rightFootPainMap,
      }));

      navigate(routes.videoTutorial);
      return;
    }

    const payload = { ...order, rightFootPainMap };
    setIsLoading(true);

    try {
      const createdOrder = await createOrder(payload);

      setOrder(createdOrder);

      navigate(routes.videoTutorial);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRightStateChange = (zone) => {
    setRightFoot((prev) => ({
      ...prev,
      [zone]: !prev[zone],
    }));
  };

  const getButtonContent = () => {
    if (isLoading) {
      return <CircularProgress color="green" />;
    }

    return isFootPain ? 'Next' : 'No Pain';
  };

  return (
    <ContentContainer>
      <SectionWrapper>
        <RightFootMap onStateChange={handleRightStateChange} rightFoot={rightFoot} />

        <FootPainParagraph foot="Right" />

        <ButtonsWrapper>
          <ButtonColored
            width="100%"
            color={isFootPain ? colors.white : colors.black}
            backgroundColor={isFootPain ? colors.green : colors.blue}
            fontWeight={isFootPain ? '600' : '400'}
            onClick={handleOrderSubmit}
          >
            {getButtonContent()}
          </ButtonColored>
        </ButtonsWrapper>
      </SectionWrapper>
    </ContentContainer>
  );
}

export default RightPainMap;
