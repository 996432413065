import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .trim()
    .min(6)
    .matches(/.*[0-9].*/, 'Password must contain at least 1 number and 6 characters')
    .required(),
});

export const createNewPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .matches(/.*[0-9].*/, 'Password must contain at least 1 number and 6 characters')
    .max(12, 'Password cannot exceed more than 12 characters'),
  repeatPassword: yup
    .string()
    .required('Repeat Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .max(12, 'Password cannot exceed more than 12 characters')
    .oneOf([yup.ref('password')], 'Password doesn’t match. Please double-check it'),
});

export const userInfoSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  weight: yup.string().required(),
  heightFeet: yup.string().required(),
  heightInches: yup.string().required(),
  gender: yup.string().required(),
  jobTitle: yup.string().required(),
  isTerms: yup.boolean(),
  phoneNumber: yup.string().required(),
});

export const userBasicInfoSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  jobTitle: yup.string().required(),
  phoneNumber: yup.string().required(),
});

export const userAboutYouInfoSchema = yup.object().shape({
  dateOfBirth: yup.string().required(),
  gender: yup.string().required(),
  weight: yup.string().required(),
  heightFeet: yup.string().required(),
  heightInches: yup.string().required(),
});

export const userShippingInfoSchema = yup.object().shape({
  address: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zipCode: yup.string().required(),
  apartmentNumber: yup.string(),
});

export const userconfirmUserInfoSchema = yup.object().shape({
  address: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zipCode: yup.string().required(),
  apartmentNumber: yup.string(),
  name: yup
    .string()
    .trim()
    .matches(/^([\w]{2,})+\s+([\w\s]{2,})+$/i, 'Should be full name')
    .required(),
  jobTitle: yup.string().required(),
});