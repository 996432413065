import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactGA from "react-ga4";
import { createBrowserHistory } from 'history';
import { update as updateIntercom } from './intercom';
import { NotificationsBlock } from './components/molecules/NotificationsBlock';
import Dev from './pages/Dev/index';
import Feedback from './pages/Feedback/index';
import Register from './pages/Register/index';
import Login from './pages/Login/index';
import ForgotPassword from './pages/ForgotPassword/index';
import CreatePassword from './pages/CreatePassword/index';
import { PrivateRoute } from './components/PrivateRoute';
import CustomerReview from './pages/CustomerReview/index';
import ChooseFlowPage from './pages/ChooseFlowPage';
import InsoleFormPage from './pages/InsoleFormPage';
import HaveDiabetesPage from './pages/HaveDiabetesPage';
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import ShoeInfoPage from './pages/ShoeInfoPage';
import UserInfo from './pages/UserInfo';
import { routes } from './constants';
import { OrdersRoutes } from './components/OrdersRoutes';
import LeftPainMap from './components/organisms/OrderComponents/LeftPainMap';
import ChooseFlow from './components/organisms/OrderComponents/ChooseFlow';
import RightPainMap from './components/organisms/OrderComponents/RightPainMap';
import { VideoTutorial } from './components/organisms/OrderComponents/VideoTutorial';
import { ScanningLeftFoot } from './components/organisms/OrderComponents/ScanningLeftFoot';
import { ScanningRightFoot } from './components/organisms/OrderComponents/ScanningRightFoot';
import { ConfirmUserInfo } from './components/organisms/OrderComponents/ConfirmUserInfo';
import { TestRejection } from './pages/TestRejection';

ReactGA.initialize("G-SFHTCCD180");

const history = createBrowserHistory();

history.listen((location) => {
  updateIntercom();
});

// function App(){
//   return (
//     <div className="maintenance-page">
//       <h1>Website Under Maintenance</h1>
//       <p>
//         We are currently performing scheduled maintenance on our website. We
//         apologize for any inconvenience caused. The website will be back online
//         by noon CST on 7/13/23.
//       </p>
//     </div>
//   );
// }

function App() {
  return (
    <div className="main-wrapper">
      <Router history={history}>
        <NotificationsBlock />

        <Routes>
          <Route path={routes.dev} element={<Dev />} />
          <Route path="/test-rejection" element={<TestRejection />} />

          <Route path={routes.register} element={<Register />} />
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.forgotPassword} element={<ForgotPassword />} />
          <Route path={routes.createPassword} element={<CreatePassword />} />

          <Route element={<PrivateRoute />}>
            <Route path={routes.home} element={<ChooseFlow />} />

            <Route element={<OrdersRoutes />}>
              <Route path={routes.home} element={<ChooseFlowPage />} />
              <Route path={routes.insoleForm} element={<InsoleFormPage />} />
              <Route path={routes.haveDiabetes} element={<HaveDiabetesPage />} />
              <Route path={routes.shoeInfo} element={<ShoeInfoPage />} />

              <Route path={routes.leftPainMap} element={<LeftPainMap />} />
              <Route path={routes.rightPainMap} element={<RightPainMap />} />
              <Route path={routes.videoTutorial} element={<VideoTutorial />} />
              <Route path={routes.scanningLeftFoot} element={<ScanningLeftFoot />} />
              <Route path={routes.scanningRightFoot} element={<ScanningRightFoot />} />
              <Route path={routes.confirmUserInfo} element={<ConfirmUserInfo />} />
            </Route>
            <Route path={routes.confirmationOrder} element={<OrderConfirmationPage />} />

            <Route path={routes.configureAccount} element={<UserInfo />} />
            <Route path={routes.customerReview} element={<CustomerReview />} />

            <Route path={routes.feedback} element={<Feedback />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
