// Function to get item from local storage
export const getItemFromLocalStorage = (key) => {
  // Retrieve item from local storage
  const item = window.localStorage.getItem(key);

  // If item exists, parse it as JSON and return it, otherwise return null
  return item ? JSON.parse(item) : null;
};

// Function to set item to local storage
export const setItemToLocalStorage = (key, value) => {
  // Check if both key and value are provided
  if (key && value) {
    // Set item to local storage as stringified JSON
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeItemFromLocalStorage = (key) => {
  // Check if both key and value are provided
  if (key) {
    // Set item to local storage as stringified JSON
    window.localStorage.removeItem(key);
  }
};