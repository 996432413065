import React, { useState } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { addressFields, states } from '../../constants';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import styles from '../form-components/form.module.scss';

export const AddressBlock = ({ setValue, watch, errors }) => {
  const prevZipCode = watch('zipCode');
  const address = watch('address');
  const state = watch('state');
  const apartmentNumber = watch('apartmentNumber');
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      libraries: ['places'],
      options: {
        types: ['address'],
        fields: ['address_components', 'geometry', 'icon', 'name', 'formatted_address'],
        componentRestrictions: {
          country: 'us', // Restrict the search to the United States
        },
      },
      language: 'en',
      debounce: 300,
    });

  const [isAutocompleteVisible, setIsAutocompleteVisible] = useState(false);

  const handleAddressChange = (evt, field) => {
    setValue(field, evt.target.value)

    if (field === addressFields.address) {
      getPlacePredictions({ input: evt.target.value });
      setIsAutocompleteVisible(true);
    }
  };

  const getPostalCode = async (placeId) =>
    new Promise((resolve) => {
      placesService?.getDetails(
        {
          placeId,
        },
        (placeDetails) => {
          let postcode = null;
          placeDetails?.address_components?.forEach((entry) => {
            if (entry.types?.[0] === 'postal_code') {
              postcode = entry.long_name;
            }
          });
          return resolve(postcode);
        }
      );
    });

  const handleAutocompleteSubmit = async (place) => {
    const zipCode = await getPostalCode(place.place_id);

    const data = place.description.split(',');

    setValue('address', place.description)
    setValue('city', data[1].trim())
    setValue('state', data[2].trim())
    setValue('zipCode', zipCode || prevZipCode)

    setIsAutocompleteVisible(false);
  };

  return (
    <>
      <Box sx={{mb: '16px', mt: '10px'}}>
        <label className={styles.text_field}>
          <span className={styles.text_label}>Address</span>
          <input
            required
            type="text"
            placeholder="Street Address, Unit#"
            className={styles.input}
            value={address}
            onChange={(e) => handleAddressChange(e, addressFields.address)}
          />
        </label>
        {isAutocompleteVisible && (
          <div className={styles.autocomplete_block}>
            {!isPlacePredictionsLoading && isAutocompleteVisible && placePredictions.length > 0 && (
              <ul className={styles.autocomplete_list}>
                {placePredictions.map((place) => (
                  <li
                    className={styles.autocomplete_item}
                    onClick={() => handleAutocompleteSubmit(place)}
                    key={place.place_id}
                  >
                    {place.description}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {errors.address && (
          <Typography
            variant="body2"
            sx={{
              color: '#F45532',
              fontWeight: 400,
              fontSize: '10px',
              lineHeight: '12px',
              letterSpacing: '0.15px',
              mt: '10px',
              ml: '12px'
            }}
          >
            {errors.address.message}
          </Typography>
        )}
      </Box>


      <Box sx={{mb: '16px'}}>
        <label className={styles.text_field}>
          <input
            type="text"
            placeholder="Unit/Apartment number"
            className={styles.input}
            value={apartmentNumber}
            onChange={(event) => setValue('apartmentNumber', event.target.value)}
          />
        </label>
      </Box>


      <Box sx={{ alignItems: 'flex-start', mb: '16px' }} className={styles.inputs_wrapper}>
        <Box sx={{ width: '50%' }}>
          <input
            value={prevZipCode}
            onChange={(e) => handleAddressChange(e, addressFields.zipCode)}
            required
            type="number"
            placeholder="Zip Code"
            className={styles.input}
          />
          {errors.zipCode && (
            <Typography
              variant="body2"
              sx={{
                color: '#F45532',
                fontWeight: 400,
                fontSize: '10px',
                lineHeight: '12px',
                letterSpacing: '0.15px',
                mt: '10px',
                ml: '12px'
              }}
            >
              {errors.zipCode.message}
            </Typography>
          )}
        </Box>

        <Box sx={{ width: '50%' }}>
          <FormControl fullWidth>
            <Select
              id="select-state"
              onChange={(e) => handleAddressChange(e, addressFields.state)}
              value={state}
              name="state"
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                borderRadius: '24px',
                height: '50px',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '0.5px solid #6F797B',
                  borderRadius: '24px',
                },
              }}
            >
              <MenuItem value="" disabled>
                State
              </MenuItem>
              {states.map((state) => (
                <MenuItem key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.state && (
            <Typography
              variant="body2"
              sx={{
                color: '#F45532',
                fontWeight: 400,
                fontSize: '10px',
                lineHeight: '12px',
                letterSpacing: '0.15px',
                mt: '10px',
                ml: '12px'
              }}
            >
              {errors.state.message}
            </Typography>
          )}
        </Box>

      </Box>
    </>
  );
};
