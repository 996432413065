import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { ButtonOutlined } from '@components/atoms/buttons';
import { useOrder } from '@components/OrderContext';
import { routes, diabetesCard, colors } from '@constants';

import styles from '@assets/styles/OrderStyles/chooseFlow.module.scss';

function HaveDiabetes() {
  const navigate = useNavigate();
  const { setOrder } = useOrder();

  const handleDiabeticStatus = (status) => {
    setOrder((prev) => ({
      ...prev,
      diabeticStatus: status,
    }));

    navigate(routes.shoeInfo);
  };

  return (
    <section id="landing">
      <div className="container">
        <div className="row">
          <div className={styles.diabetes__wrapper}>
            <Card sx={diabetesCard}>
              <CardContent sx={{ p: 0 }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className={styles.diabetes__card_title}
                >
                  Do you have diabetes?
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={styles.diabetes__card_subtitle}
                >
                  We're gathering this information for understanding our user base better. Your data
                  is stored anonymously and not shared with your employer.
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: 'flex', justifyContent: 'space-between', pt: '32px', px: 0 }}
              >
                <ButtonOutlined
                  onClick={() => handleDiabeticStatus('yes')}
                  color={colors.black}
                  width="140px"
                  height="40px"
                  fontSize="16px"
                >
                  Yes
                </ButtonOutlined>

                <ButtonOutlined
                  onClick={() => handleDiabeticStatus('no')}
                  color={colors.black}
                  width="140px"
                  height="40px"
                  fontSize="16px"
                >
                  No
                </ButtonOutlined>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HaveDiabetes;
