import React from 'react';
import cn from 'classnames';

import describeStyles from '@assets/styles/describePain.module.scss';

export const InputCheckbox = ({ id, name, checked, onChange }) => (
  <label
    htmlFor={id}
    className={cn(
      describeStyles.insole_describe__pain,
      {[describeStyles.insole_describe__pain_checked]: checked}
    )}
  >
    {name}
    <input
      onChange={onChange}
      type="checkbox"
      id={id}
      className="describe__option"
      checked={checked}
    />
  </label>
);
