import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { AccountIcon } from '../atoms/Icons/MenuIcons/AccountIcon';
import { SignOutIcon } from '../atoms/Icons/MenuIcons/SignOutIcon';
import { logout } from '@api/auth';
import { removeItemFromLocalStorage } from '@helpers/localstorage-helper';
import { errorHandler } from '@helpers/error-handler';
import { routes, storageNames, listItemText } from '@constants';


export const MainMenu = ({ setToggleMenu, open }) => {
    const navigate = useNavigate();

    const handleLogOut = async () => {
        try {
            await logout();

            removeItemFromLocalStorage(storageNames.tokens);
            removeItemFromLocalStorage(storageNames.user);

            navigate(routes.login);
        } catch (e) {
            errorHandler(e);
        }
    }

    return (
        <SwipeableDrawer
            anchor={'top'}
            open={open['top']}
            onClose={setToggleMenu('top', false)}
            onOpen={setToggleMenu('top', true)}
        >
            <Box
                sx={{ width: 'auto', my: 10, height: '100vh' }}
                role="presentation"
                onClick={setToggleMenu('top', false)}
                onKeyDown={setToggleMenu('top', false)}
            >
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mb: '30px' }}>
                    <Button sx={{ borderRadius: '10px', mr: '10px' }} onClick={setToggleMenu('top', false)}>
                        <CancelOutlinedIcon color="secondary" fontSize="large" />
                    </Button>
                </Box>
                <List sx={{ p: '16px' }}>
                    <ListItem disablePadding sx={{ mb: '8px' }}>
                        <ListItemButton onClick={() => navigate(routes.configureAccount)}>
                            <ListItemIcon>
                                <AccountIcon />
                            </ListItemIcon>
                            <ListItemText sx={listItemText} primary={'Your Account'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ mb: '8px' }}>
                        <ListItemButton onClick={() => handleLogOut()}>
                            <ListItemIcon>
                                <SignOutIcon />
                            </ListItemIcon>
                            <ListItemText sx={listItemText} primary={'Sign Out'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ mb: '8px' }}>
                        <ListItemButton onClick={() => navigate(routes.customerReview)}>
                            <ListItemIcon>
                                <ForumOutlinedIcon sx={{ width: '46px', height: '46px', color: '#000' }} fontSize="large" />
                            </ListItemIcon>
                            <ListItemText sx={listItemText} primary={'Feedback'} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </SwipeableDrawer>
    );
};
