import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useOrder } from '@components/OrderContext';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonColored } from '@components/atoms/buttons';
import { registration } from '@api/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { TextInput } from '@components/form-components';
import { errorHandler } from '@helpers/error-handler';
import { setItemToLocalStorage } from '@helpers/localstorage-helper';
import { routes, storageNames, loginTitle, colors, fieldAttrs } from '@constants';
import TermsOfService from '@assets/TermsOfServiceHikeMedical.pdf';
import Logo from "../../assets/Hike logo variations-02 (1) (1).png"
import formStyles from '@assets/styles/forms.module.scss';
import inputStyles from '@assets/styles/inputFields.module.scss';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup
    .string()
    .trim()
    .min(6)
    .matches(/.*[0-9].*/, 'Password must contain at least 1 number and 6 characters')
    .required('Password is required'),
});

const Register = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const company = searchParams.get('companyCode');
  const [companyCode, setCompanyCode] = useState(821619); 
  const { setUser } = useOrder();
  const [agreesToTerms, setAgreesToTerms] = useState(false);
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = handleSubmit(async (data) => {
    if(company) {
      setCompanyCode(company);
    }
    try {
      const result = await registration({ ...data, companyCode });

      setItemToLocalStorage(storageNames.tokens, result.tokens);
      setUser(result.user);

      toast.success('You successfully registered');
      navigate(routes.configureAccount);
    } catch (e) {
      errorHandler(e);
    }
  });

  return (
    <div className="container">
      <div className="row">
        <form onSubmit={onSubmit} className={formStyles.form__login_wrapper}>
        <Box
  sx={{
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // Align items vertically to center them
    mb: '40px'
  }}
>
  <Typography gutterBottom variant="h1" sx={loginTitle}>
    Sign Up
  </Typography>

  <img src={Logo} height={90} width={90} />
</Box>

          <TextInput register={register} error={errors.email} properties={fieldAttrs.email} />
          <TextInput register={register} error={errors.password} properties={fieldAttrs.registerPassword} />

          <label htmlFor="terms-of-service" className={inputStyles.label__terms}>
            <input
              type="checkbox"
              id="terms-of-service"
              className={inputStyles.terms__box}
              checked={agreesToTerms}
              onChange={() => setAgreesToTerms((prev) => !prev)}
              required
            />
            I Agree to Hike Medical's Terms of Service
          </label>
          <Link className={inputStyles.terms} to={TermsOfService} target="_blank">
            View Terms of Service
          </Link>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              my: '30px',
            }}
          >
            <ButtonColored
              color={colors.black}
              backgroundColor={colors.yellow}
              width={'100%'}
              onClick={onSubmit}
              type="submit"
              disabled={isSubmitting || !isValid || !agreesToTerms}
            >
              {isSubmitting ? <CircularProgress color="green" /> : 'Create Account'}
            </ButtonColored>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default Register;
