import { createContext, useContext, useState } from 'react';

const OrderContext = createContext(null);
export const useOrder = () => useContext(OrderContext);

export const OrderProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [order, setOrder] = useState(null);
  const [flowType, setFlowType] = useState(null);

  return (
    <OrderContext.Provider value={{ user, setUser, order, setOrder, flowType, setFlowType }}>
      {children}
    </OrderContext.Provider>
  );
};

// import { createContext, useContext, useState } from 'react';

// const OrderContext = createContext(null);
// export const useOrder = () => useContext(OrderContext);

// export const OrderProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [order, setOrder2] = useState(null);
//   const [flowType, setFlowType] = useState(null);

//   const setOrder = (value) =>
//   {
//     console.log("Value: ")
//     console.log(value)
//     console.log("Current order: ")
//     console.log(order)
//     setOrder2(value)
//   }

//   return (
//     <OrderContext.Provider value={{ user, setUser, order, setOrder, flowType, setFlowType }}>
//       {children}
//     </OrderContext.Provider>
//   );
// };