import React, { useState } from 'react';
import { ContentContainer } from '@components/atoms/ContentContainer';

import formStyles from '../../components/form-components/form.module.scss';
import { AboutYou, BasicInfo, ShippingAddress } from '../../components/account-components';

const UserInfo = () => {
  const [configureStep, setConfigureStep] = useState(0);

  const renderContent = () => {
    switch (configureStep) {
      case 1:
        return (
          <AboutYou setConfigureStep={setConfigureStep}/>
        );
      case 2:
        return (
          <ShippingAddress setConfigureStep={setConfigureStep}/>
        );
      default:
        return (
          <BasicInfo setConfigureStep={setConfigureStep}/>
        );
    }
  };

  return (
    <ContentContainer>
      <div className="content_centered">
        <div className={formStyles.form_inputs}>
          {renderContent()}
        </div>
      </div>
    </ContentContainer>
  );
};

export default UserInfo;
