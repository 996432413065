export const mainContainer = {
  width: '100%',
  height: '100svh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const chooseSoloFlowCard = {
  width: '100%',
  height: 320,
  p: 2,
  my: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  borderRadius: '28px',
  backgroundColor: 'transparent',
  boxShadow: '0 5px 7px rgba(0, 0, 0, 0.2)',
};

export const choosePartnerFlowCard = {
  backgroundColor: 'transparent',
};

export const cardMediaSolo = {
  height: 168,
  width: 143,
  position: 'absolute',
  top: 5,
  left: '55%',
  transform: 'translateX(-50%)',
};

export const cardMediaSoloNew = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
};

export const cardMediaPartner = {
  height: 136,
  width: 294,
  left: '52%',
};

export const diabetesCard = {
  width: '100%',
  padding: '60px 30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  borderRadius: '28px',
  boxShadow: 'none',
  border: '0.5px solid #6F797B',
  backgroundColor: '#BDE0FE',
};

export const chooseMainTitle = {
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 700,
  fontSize: '36px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#000',
  marginBottom: '0 !important',
};

export const chooseCardTitle = {
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#001F24',
  marginBottom: '0 !important',
};

export const chooseCardTitleNew = {
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#3BC8C8',
  marginBottom: '10px !important',
  textAlign: 'center'
};

export const chooseCardSubtitle = {
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  color: '#001F24',
};

export const chooseCardSubtitleNew = {
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 300,
  fontSize: '18px',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  color: '#3BC8C8',
  textAlign: 'center'
};

export const confirmationTitle = {
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '40px',
  letterSpacing: '0.15px',
  color: '#000',
  marginBottom: '0 !important',
};

export const confirmationSubtitle = {
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '40px',
  letterSpacing: '0.15px',
  color: '#000',
};

export const insoleButton = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '28px',
  border: '0.5px solid #6F797B',
  py: '18px',
  px: '20px',
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  backgroundColor: '#006875',
  color: 'white',
};

export const insoleChip = {
  backgroundColor: '#9EEFFF',
  pt: '7px',
  pb: '7px',
  px: '15px',
  height: '54px',
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 500,
  fontSize: '24px',
  lineHeight: '40px',
  display: 'flex',
  alignItems: 'flex-end',
  letterSpacing: '0.15px',
  color: '#000000',
  borderRadius: '28px',
};

export const listItemText = {
  '& .MuiTypography-root': {
    color: '#000',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '40px',
    letterSpacing: '0.15px',
  },
};

export const shoeInfoSelectTextDown = {
  width: '100%',
  height: 80,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  borderRadius: '28px',
};

export const shoeInfoSmallSelectTextDown = {
  width: '100%',
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  borderBottomLeftRadius: '28px',
  borderBottomRightRadius: '28px',
};

export const shoeInfoSizeSelectText = {
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 700,
  fontSize: '36px',
  color: '#000000',
};

export const shoeInfoWidthSelectText = {
  fontStyle: 'normal',
  fontFamily: 'Noto Sans',
  fontWeight: 400,
  fontSize: '20px',
  color: '#000000',
};

export const shoeInfoSelectContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: 320,
  backgroundColor: 'white',
  border: '4px solid #6F797B',
  borderRadius: '28px',
};

export const shoeInfoSelectInput = {
  outline: 'none',
  textAlign: 'center',
  fontFamily: 'Noto Sans',
  padding: '0 15px',
  width: '150px',
  fontWeight: 600,
  fontSize: '32px',
};

export const insoleFormRadioButton = {
  mx: 0,
  px: '30px',
  py: '5px',
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '40px',
  letterSpacing: '0.15px',
  transition: 'all 0.02s',
};

// Login
export const loginTitle = {
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '48px',
  lineHeight: '48px',
  color: '#000000',
};
