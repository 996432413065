export const gaEvents = {
    createdOrderOnFirstVisit: {
        category: 'Order placed on first visit',
        action: 'Created order placed on first visit',
        label: 'Submitted order on first visit'
    },
    soloFlowMode: {
        category: 'Choose flow mode',
        action: 'Solo flow mode',
        label: 'Solo flow mode card'
    },
    partnerFlowMode: {
        category: 'Choose flow mode',
        action: 'Partner flow mode',
        label: 'Partner flow mode card'
    },
    applyToBothFeet: {
        category: 'Insole foot form',
        action: 'Apply to both feet',
        label: 'Apply to both feet button'
    },
    textInstructions: {
        category: 'Text instructions',
        action: 'Read instruction',
        label: 'Read instruction button'
    },
    retakeLeftFoot: {
        category: 'Retake scanning foot',
        action: 'Retake scanning left foot',
        label: 'Retake scanning left foot button'
    },
    retakeRightFoot: {
        category: 'Retake scanning foot',
        action: 'Retake scanning right foot',
        label: 'Retake scanning right foot button'
    },
    timeSpentOnVideoWatchingScreen: {
        category: 'Time spent on video watching screen',
        action: 'Time spent on video watching screen',
        label: 'Time spent on '
    }
}