import React from 'react';   
import { Link, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { loginSchema } from '../../validation/index';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import toast from 'react-hot-toast';
import { useOrder } from '@components/OrderContext';
import { ButtonColored } from '@components/atoms/buttons';
import { login } from '@api/auth';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from "../../assets/Hike logo variations-02 (1) (1).png"
import { errorHandler } from '@helpers/error-handler';
import { setItemToLocalStorage } from '@helpers/localstorage-helper';
import { routes, storageNames, loginTitle, colors, fieldAttrs } from '@constants';
import { TextInput } from '../../components/form-components';

import formStyles from '@assets/styles/forms.module.scss';
import inputStyles from '../../components/form-components/form.module.scss';

const Login = () => {
  const navigate = useNavigate();
  const { setUser } = useOrder();
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(loginSchema) });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await login(data);

      setItemToLocalStorage(storageNames.tokens, result.tokens);
      setUser(result.user);

      toast.success('Login Successful');
      navigate(routes.home);
    } catch (e) {
      errorHandler(e);
    }
  });

  return (
    <div className="container">
      <div className="row">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className={formStyles.form__login_wrapper}>
          <Box
  sx={{
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // Align items vertically to center them
    mb: '40px'
  }}
>
  <Typography gutterBottom variant="h1" sx={loginTitle}>
    Log in
  </Typography>

  <img src={Logo} height={90} width={90} />
</Box>

            <Box sx={{ width: '100%' }} className={inputStyles.confirm_form_inputs}>
              <TextInput register={register} error={errors.email} properties={fieldAttrs.email} />

              <TextInput
                register={register}
                error={errors.password}
                properties={fieldAttrs.password}
              />
            </Box>

            <Box
  sx={{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end', // Align content to the right side
    justifyContent: 'flex-start', // Align content to the top
    my: '30px',
  }}
>
  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <Link to={routes.forgotPassword} className={formStyles.forgotpass__link}>
      Forgot password
    </Link>

    <ButtonColored
      color={colors.black}
      backgroundColor={colors.yellow}
      onClick={onSubmit}
      type="submit"
      disabled={isSubmitting || !isValid}
    >
      {isSubmitting ? <CircularProgress color="green" /> : 'Log In'}
    </ButtonColored>
  </div>

  <div style={{ width: '100%', textAlign: 'right', marginTop: '30px' }}>
    <Link
      to={routes.register}
      className={formStyles.forgotpass__link}
      style={{ fontSize: '20px', padding: '14px 30px' }} // Adjust size as needed
    >
      Register
    </Link>
  </div>
</Box>

          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Login;
