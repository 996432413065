import React, { useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import Webcam from 'react-webcam';
import { useOrder } from '../../OrderContext';
import { VideoPreview } from '../VideoPreview';
import { VideoActionsBlock } from '../VideoActionsBlock';
import { gaEvents } from '@constants';

import imagesStyles from '@assets/styles/images.module.scss';

const MAX_RECORDING_TIME = 35;

export const VideoRecording = ({ footName, setBlob, onFileSave, isLoading }) => {
  const { flowType } = useOrder();
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [preview, setPreview] = useState(null);
  const cameraMode = flowType === 'withPartner' ? 'environment' : 'user';
  const [facingMode, setFacingMode] = useState(cameraMode);
  const mimeType = MediaRecorder.isTypeSupported('video/mp4') ? 'video/mp4' : 'video/webm';
  const [timeRemaining, setTimeRemaining] = useState(MAX_RECORDING_TIME);

  const handleStartCaptureClick = React.useCallback(async () => {
    setCapturing(true);
    setTimeRemaining(MAX_RECORDING_TIME);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType,
    });
    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);

    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(({ data }) => {
    if (data.size > 0) {
      const blob = new Blob([data], {
        type: 'video/mp4',
      });
      setBlob(blob);
      setPreview(URL.createObjectURL(blob));
    }
  }, []);

  const handleStopCaptureClick = React.useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }
  }, [mediaRecorderRef, setCapturing]);

  const handleRetake = () => {
    if (footName === 'Left Foot') {
      ReactGA.event(gaEvents.retakeLeftFoot);
    } else {
      ReactGA.event(gaEvents.retakeRightFoot);
    }

    setBlob(null);
    setPreview(null);
    setTimeRemaining(MAX_RECORDING_TIME);
  };

  useEffect(() => {
    let intervalId;
    if (capturing && timeRemaining > 0) {
      intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      handleStopCaptureClick();
    }
    return () => clearInterval(intervalId);
  }, [capturing, timeRemaining, handleStartCaptureClick]);

  const toggleCamera = () => {
    if (facingMode === 'user') {
      setFacingMode('environment');
    } else {
      setFacingMode('user');
    }
  };

  return (
    <>
      {preview ? (
        <VideoPreview
          preview={preview}
          handleRetake={handleRetake}
          handleSave={onFileSave}
          isLoading={isLoading}
        />
      ) : (
        <>
          <div className={imagesStyles.webcam__wrapper}>
            <div className={imagesStyles.videoCapture__frame}>
              <p className={imagesStyles.capture__timer}>{timeRemaining}</p>
            </div>

            <Webcam
              audio={false}
              ref={webcamRef}
              videoConstraints={{ facingMode, width: 1920, height: 1080 }}
              mirrored={facingMode === 'user'}
              className={imagesStyles.webcam_video}
            />

            <VideoActionsBlock
              footName={footName}
              handleStartCaptureClick={handleStartCaptureClick}
              handleStopCaptureClick={handleStopCaptureClick}
              capturing={capturing}
              toggleCamera={toggleCamera}
            />
          </div>
        </>
      )}
    </>
  );
};
