import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { routes } from '@constants';
import { useOrder } from './OrderContext';
import { Navigation } from './organisms/Navigation';
import Box from '@mui/material/Box';
import { excludedPaths, mainContainer } from '../constants';

export const OrdersRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { order, flowType, user } = useOrder();

  useEffect(() => {
    if (!order || !flowType || !user.patientInfo) {
      toast.error('You need choose flow first');
      navigate(routes.home);
    }
  }, []);

  return (
    <Box sx={mainContainer}>
      {excludedPaths.includes(location.pathname) ? (
        <Outlet />
      ) : (
        <>
          <Navigation />

          <Outlet />
          <div style={{ height: '40px' }}></div>
        </>
      )}
    </Box>
  );
};
