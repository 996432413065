import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ShoeInfoSelect from '@components/atoms/Inputs/ShoeInfoSelect';
import { useOrder } from '@components/OrderContext';
import { ButtonColored } from '@components/atoms/buttons';
import { routes, insoleButton, colors } from '@constants';

import formStyles from '@assets/styles/forms.module.scss';

function ShoeInfo() {
  const navigate = useNavigate();
  const { order, setOrder } = useOrder();
  const [brandError, setBrandError] = useState(false);
  const shoeSizeOptions = new Array(40)
    .fill(1)
    .map((_, i) => i > 8 && (i + 1) / 2)
    .filter((element) => element !== false);

  const shoeWidthOptions = ['narrow', 'normal', 'wide', 'unsure'];

  const handleNextStep = () => {
    const brand = order['shoeBrand'];

    if (!brand || brand === '') {
      setBrandError(true);
    } else {
      navigate(routes.leftPainMap);
    }
  };

  useEffect(() => {
    setOrder((prev) => ({
      ...prev,
      shoeWidth: order?.shoeWidth ? order?.shoeWidth : 'narrow',
      shoeSize: order?.shoeSize ? order?.shoeSize : 5,
      shoeBrand: order?.shoeBrand ? order?.shoeBrand : '',
    }));
  }, [setOrder, order?.shoeWidth, order?.shoeSize, order?.shoeBrand]);

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className={formStyles.form__wrapper} style={{ height: 'auto' }}>
            <h1 className={formStyles.form__shoeinfo_title}>
              Which type of shoes will you pair with our insole?
            </h1>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: '20px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <ShoeInfoSelect
                  options={shoeWidthOptions}
                  label={'Width'}
                  heightBox={80}
                  defaultValue={'narrow'}
                  type="shoeWidth"
                />
                <ShoeInfoSelect
                  label={'Brand'}
                  heightBox={80}
                  defaultValue={''}
                  type="shoeBrand"
                  error={brandError}
                />
              </Box>
              <Box sx={{width: '100%'}}>
                <ShoeInfoSelect
                  options={shoeSizeOptions}
                  label={'Size'}
                  heightBox={255}
                  defaultValue={5}
                  type="shoeSize"
                />
              </Box>
            </Box>

            <Stack
              direction="row"
              spacing={2}
              sx={{ width: '100%', mt: '30px', display: 'flex', justifyContent: 'flex-end' }}
            >
              <ButtonColored
                onClick={() => handleNextStep()}
                color={colors.white}
                backgroundColor={colors.green}
                width={'169px'}
                sx={insoleButton}
              >
                Next
              </ButtonColored>
            </Stack>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ShoeInfo;
