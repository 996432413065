export const fieldAttrs = {
    email: {
        name: 'email',
        label: 'Email address',
        type: 'email',
        placeholder:"Work email"
    },
    password: {
        name: 'password',
        label: 'Password',
        type: 'password',
        placeholder:"Enter password"
    },
    repeatPassword: {
        name: 'repeatPassword',
        label: 'Repeat password',
        type: 'password',
        placeholder:"Repeat new password"
    },
    registerPassword: {
        name: 'password',
        label: 'Create Password',
        type: 'password',
        placeholder:"Enter password"
    }
}