import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Modal from 'simple-react-modal';
import OtpInput from 'react-otp-input';
import { forgotPassword, verifyCode } from '@api/auth';
import { errorHandler } from '@helpers/error-handler';
import { setItemToLocalStorage, getItemFromLocalStorage } from '@helpers/localstorage-helper';
import { routes, storageNames, fieldAttrs, formErrorMessages } from '@constants';
import { InputField } from '@components/atoms/InputField';
import { AuthTitle } from '@components/molecules/AuthTitle';

import styles from './forgotPassword.module.scss';
import buttonStyles from '@assets/styles/buttons.module.scss';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [modal, setToggleModal] = useState(false);
  const [isSentEmail, setIsSentEmail] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const sentEmail = getItemFromLocalStorage(storageNames.forgotPassEmail);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const moveToVerificationCode = () => {
    setToggleModal(false);
    setIsSentEmail(true);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await forgotPassword(data);

      if (result) {
        setItemToLocalStorage(storageNames.forgotPassEmail, data.email);

        toast.success('Email address was sent Successful');
        setToggleModal(true);
        setVerificationError(false);
      }
    } catch (error) {
      setVerificationError(true);
      errorHandler(error);
    }
  });

  const setOtpCodeToForm = (event) => {
    setOtpCode(event);
    setValue('otpCode', event);
  };

  const handleVerifyCode = handleSubmit(async (data) => {
    try {
      const result = await verifyCode(data);

      if (result) {
        navigate(routes.createPassword);
      }
    } catch (error) {
      setVerificationError(true);
      errorHandler(error);
    }
  });

  return (
    <section id="forgotPassword" className={styles.forgotpass__section}>
      <div className="container">
        <div className="row">
          <Modal
            className="customModal"
            containerClassName="customModal__inner"
            transitionSpeed={1500}
            show={modal}
          >
            <div>
              <div className={styles.modal_title_wrapper}>
                <h3 className={styles.modal_title}>Please check your email address</h3>
                <p className={styles.modal_subtitle}>
                  The verification code has been sent to {sentEmail}
                </p>
              </div>

              <button
                type="button"
                className={buttonStyles.modal__button}
                onClick={moveToVerificationCode}
              >
                Ok
              </button>
            </div>
          </Modal>
          <div className={styles.forgotpass__wrapper}>
            <div className={styles.forgotpass__fields__wrapper}>
              {!isSentEmail && (
                <form onSubmit={onSubmit} className={styles.form__wrapper}>
                  <>
                    <AuthTitle
                      title={'Forgot password'}
                      subTitle={
                        'Please, provide us with your email address and we will send you a verification code'
                      }
                    />
                    <InputField
                      register={register}
                      error={errors.email}
                      properties={fieldAttrs.email}
                    />
                  </>

                  {verificationError && (
                    <span className={styles.verification_error}>
                      {formErrorMessages.USER_NOT_FOUND}
                    </span>
                  )}

                  <button
                    type="submit"
                    className={buttonStyles.forgotpass__button}
                    disabled={isSubmitting}
                  >
                    Send
                  </button>
                </form>
              )}

              {isSentEmail && (
                <form onSubmit={handleVerifyCode} className={styles.form__wrapper}>
                  <AuthTitle
                    title={'Forgot password'}
                    subTitle={
                      'Please, provide us with your email address and we will send you a verification code'
                    }
                  />

                  <div className={styles.forgotpass_otpcode_wrapper}>
                    <OtpInput
                      value={otpCode}
                      onChange={(event) => setOtpCodeToForm(event)}
                      numInputs={4}
                      placeholder={'0000'}
                      shouldAutoFocus
                      renderInput={(props) => <input {...props} />}
                      inputStyle={`input_custom ${verificationError && 'error'}`}
                    />
                    {verificationError && (
                      <span className={styles.verification_error}>
                        {formErrorMessages.SEND_OTP_INCORRECT}
                      </span>
                    )}
                  </div>

                  <button
                    type="submit"
                    className={buttonStyles.forgotpass__button}
                    disabled={isSubmitting}
                  >
                    Send
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
