import React from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Box from '@mui/material/Box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CustomMuiSelect, DateOfBirth } from '../form-components';
import { ButtonColored } from '../atoms/buttons';
import { useOrder } from '@components/OrderContext';
import { userAboutYouInfoSchema } from '../../validation';
import { colors, configureSteps, userInfo } from '../../constants';

import formStyles from '../form-components/form.module.scss';
import buttonStyles from '@assets/styles/buttons.module.scss';

export const AboutYou = ({ setConfigureStep }) => {
  const { user, setUser } = useOrder();

  const feetOptions = new Array(8).fill(1).map((_, i) => ({ value: i, label: i + '”' }));
  const inchesOptions = new Array(12).fill(1).map((_, i) => ({ value: i, label: i + '’' }));

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userAboutYouInfoSchema),
    defaultValues: {
      dateOfBirth: user?.patientInfo?.dateOfBirth || '',
      weight: user?.patientInfo?.weight || '',
      heightFeet: user?.patientInfo?.heightFeet || '',
      heightInches: user?.patientInfo?.heightInches || '',
      gender: user?.patientInfo?.gender || '',
    },
    mode: 'onSubmit'
  });

  const dateOfBirthData = watch('dateOfBirth');

  const onSubmit = handleSubmit(async (data) => {
    setUser(prev => ({
      ...prev,
      patientInfo: {
        ...prev.patientInfo,
        ...data,
        dateOfBirth: dayjs(data.dateOfBirth).format('YYYY-MM-DD')
      }
    }))
    setConfigureStep(2)
  });

  return (
    <form onSubmit={onSubmit} className={formStyles.form}>
      <h1 className={formStyles.form_title}>{configureSteps.about}</h1>
      <Box sx={{ alignItems: 'flex-start' }} className={formStyles.inputs_wrapper}>
        <DateOfBirth value={dateOfBirthData} setValue={setValue} error={errors.dateOfBirth} />
        <CustomMuiSelect
          setValue={setValue}
          defaultValue={user?.patientInfo?.gender}
          width={'100%'}
          weightProps={userInfo.genderProps}
          error={errors.gender}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: '260px',
          display: 'flex',
          gap: '20px',
          alignItems: 'flex-start',
        }}
      >
        <CustomMuiSelect
          setValue={setValue}
          defaultValue={user?.patientInfo?.[userInfo.feetProps.name]}
          width={'100%'}
          weightProps={{ ...userInfo.feetProps, options: feetOptions }}
          error={errors.heightFeet}
        />
        <CustomMuiSelect
          setValue={setValue}
          defaultValue={user?.patientInfo?.[userInfo.inchesProps.name]}
          width={'100%'}
          weightProps={{ ...userInfo.inchesProps, options: inchesOptions }}
          hasLabel={false}
          error={errors.heightInches}
        />
      </Box>

      <CustomMuiSelect
        setValue={setValue}
        defaultValue={user?.patientInfo?.weight}
        width={'260px'}
        weightProps={userInfo.weightProps}
        error={errors.weight}
      />

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: '16px', mt: '24px' }}>
        <button
          type="button"
          onClick={() => setConfigureStep(0)}
          className={buttonStyles.button_fab}
        >
          <ArrowBackIosNewIcon sx={{ color: '#000000' }} />
        </button>
        <ButtonColored
          color={colors.white}
          backgroundColor={colors.green}
          onClick={onSubmit}
        >
          Next
        </ButtonColored>
      </Box>
    </form>
  );
};
