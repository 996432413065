import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import { useOrder } from '@components/OrderContext';
import { updatePatientInfo } from '@api/users';
import { userShippingInfoSchema } from '../../validation';
import { AddressBlock, TermsCheckBox } from '../form-components';
import { ButtonColored } from '../atoms/buttons';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { routes, colors, configureSteps } from '../../constants';
import { errorHandler } from '@helpers/error-handler';
import CircularProgress from '@mui/material/CircularProgress';

import formStyles from '../form-components/form.module.scss';
import buttonStyles from '@assets/styles/buttons.module.scss';

export const ShippingAddress = ({ setConfigureStep }) => {
  const { user, setUser } = useOrder();
  const navigate = useNavigate();

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(userShippingInfoSchema),
    defaultValues: {
      address: user.patientInfo?.address || '',
      city: user.patientInfo?.city || '',
      state: user.patientInfo?.state || '',
      zipCode: user.patientInfo?.zipCode || '',
      apartmentNumber: user?.patientInfo?.apartmentNumber || '',
    },
    mode: 'onSubmit',
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!data.isTerms) {
      toast.error('Please, accept terms and conditions first!');
      return;
    }
    try {
      // setUser(prev => ({
      //   ...prev,
      //   patientInfo: {
      //     ...prev.patientInfo,
      //     ...data
      //   }
      // }))
      const updatedUser = await updatePatientInfo(user._id, {
          ...user.patientInfo,
          ...data
      });
      setUser(updatedUser);

      toast.success('Account info successfully updated');
      navigate(routes.home);
    } catch (e) {
      errorHandler(e);
    }
  });

  return (
    <form onSubmit={onSubmit} className={formStyles.form}>
      <h1 className={formStyles.form_title}>{configureSteps.shipping}</h1>
      <AddressBlock setValue={setValue} watch={watch} errors={errors} />

      <TermsCheckBox defaultChecked={user.patientInfo?.isTerms || false} register={register} />

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          mb: '16px',
          mt: '24px',
        }}
      >
        <button
          type="button"
          onClick={() => setConfigureStep(1)}
          className={buttonStyles.button_fab}
        >
          <ArrowBackIosNewIcon sx={{ color: '#000000' }} />
        </button>
        <ButtonColored color={colors.black} backgroundColor={colors.yellow} onClick={onSubmit}>
          {isSubmitting ? <CircularProgress color="green" /> : 'Submit'}
        </ButtonColored>
      </Box>
    </form>
  );
};
