import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useOrder } from '../../OrderContext';
import { errorHandler } from '../../../helpers/error-handler';
import { createFileName } from '../../../helpers/create-file-name';
import { uploadFileToAWS } from '../../../api/AWS-service';
import { VideoRecording } from './VideoRecording';
import { routes } from '../../../constants';

export const ScanningRightFoot = () => {
  const navigate = useNavigate();
  const { order, setOrder } = useOrder();
  const [isLoading, setIsLoading] = useState(false);
  const [blob, setBlob] = useState(null);

  const saveFile = async () => {
    setIsLoading(true);

    try {
      const fileName = createFileName(order, 'RIGHT.mp4');

      const { Key } = await uploadFileToAWS(fileName, blob);
      setOrder((prev) => ({
        ...prev,
        rightVideoPath: Key,
      }));

      // await validateVideo(Key);

      toast.success('Video successfully uploaded');
      navigate(routes.confirmUserInfo);
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VideoRecording
      footName="Right Foot"
      setBlob={setBlob}
      onFileSave={saveFile}
      isLoading={isLoading}
    />
  );
};
