import $api, { ENDPOINTS } from './index';
import { dummyOrder } from '../constants/dummy-order';

export const createOrder = (payload) => {
  if (process.env.NODE_ENV !== 'production') {
    return Promise.resolve({ ...dummyOrder, ...payload });
  }

  return $api.post(ENDPOINTS.orders, payload);
};

export const completeOrder = (id, payload) => {
  if (process.env.NODE_ENV !== 'production') {
    return Promise.resolve({ ...dummyOrder, ...payload });
  }

  return $api.patch(ENDPOINTS.completeOrder(id), payload);
};
