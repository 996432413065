import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import CircularProgress from '@mui/material/CircularProgress';
import { uploadFileToAWS } from '@api/AWS-service';
import { triggerScanTool } from '@api/scan-triggers';

import inputStyles from '@assets/styles/inputFields.module.scss';
import formStyles from '@assets/styles/forms.module.scss';
import buttonStyles from '@assets/styles/buttons.module.scss';

const Dev = () => {
  const {
    formState: { isSubmitting },
    handleSubmit,
    register,
  } = useForm();

  const onSubmit = handleSubmit(async ({ image, leftFoot, rightFoot }) => {
    const folder = 'test';
    const uniqId = uuidv4();
    const path = `${folder}/${uniqId}/test`;

    try {
      const requests = [image[0], leftFoot[0], rightFoot[0]].map((file, i) => {
        const lastPart = i === 1 ? 'Left' : i === 2 ? 'Right' : '';
        const mimeType = file.type.split('/')[1];
        const fileName = path + lastPart + '.' + mimeType;

        return uploadFileToAWS(fileName, file);
      });

      await Promise.all(requests);
      await triggerScanTool(folder, path, uniqId);

      toast.success('Files have been successfully submitted');
    } catch (e) {
      toast.error('Something went wrong');
    }
  });

  return (
    <section id="landing">
      <div className="container">
        <div className="row">
          <form onSubmit={onSubmit} className={formStyles.form__wrapper}>
            <h1 className="section__title">Hike Medical Training Data Collection</h1>

            <h2 className="choose__title">Upload image</h2>

            <input
              className={inputStyles.input__file}
              type="file"
              accept="image/*"
              name="image"
              {...register('image')}
              required
            />

            <h2 className="choose__title">Upload left foot video</h2>

            <input
              className={inputStyles.input__file}
              type="file"
              accept="video/*"
              name="leftFoot"
              {...register('leftFoot')}
              required
            />

            <h2 className="choose__title">Upload right foot video</h2>

            <input
              className={inputStyles.input__file}
              type="file"
              accept="video/*"
              name="rightFoot"
              {...register('rightFoot')}
              required
            />

            <button className={buttonStyles.next__button} type="submit" disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress color="green" /> : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Dev;
