import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { API_URL, ENDPOINTS } from './index';
import { getVideoCheckResults } from './video-check';
import { timeout } from '../helpers';

const BASE_URL = 'https://pmhxq7ejt0.execute-api.us-east-2.amazonaws.com';
const SCAN_ENDPOINTS = {
  triggerScan: BASE_URL + '/api/video_flow',
  validateImg: BASE_URL + '/Foot_sizing',
  checkVideo: BASE_URL + '/check_video',
};

export const triggerScanTool = (companyCode, path, uniqID) => {
  if (process.env.NODE_ENV !== 'production') {
    return Promise.resolve({ data: { status: 'ok' } });
  }

  const endpoint = API_URL + ENDPOINTS.updateScanPaths(uniqID);

  return axios.post(SCAN_ENDPOINTS.triggerScan, {
    'company-id': companyCode,
    bucket_name: process.env.REACT_APP_AWS_BUCKET,
    input_usdz_path: path,
    endpoint,
  });
};

export const validateImage = (image, shoeSize) => {
  if (process.env.NODE_ENV !== 'production') {
    return Promise.resolve(true);
  }

  return axios.post(SCAN_ENDPOINTS.validateImg, { img64: image, shoe_size: shoeSize });
};

export const validateVideo = async (path) => {
  const uniqID = uuidv4();
  const data = {
    input_usdz_path: path,
    bucket_name: process.env.REACT_APP_AWS_BUCKET,
    hash: uniqID,
    callback: API_URL + ENDPOINTS.videoCheck,
  };
  const res = await axios.post(SCAN_ENDPOINTS.checkVideo, data);

  if (res.status === 200) {
    await timeout(20000);

    return getVideoCheckResults(uniqID);
  }
};
